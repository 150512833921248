<div class="row p-3">
    <div class="col-lg-4 col-md-4 col-sm-6">
        <div>ประเภทเหตุการณ์ :</div>
        <div class="d-flex align-items-center">
            <kendo-combobox class="w-100" [data]="allData" [allowCustom]="allowCustom" [suggest]="true" [textField]="'name'" [valueField]="'name'" [value]="search_txt" (valueChange)="handleValue($event)" [filterable]="true"
                (filterChange)="handleFilter($event)" [placeholder]="'ทั้งหมด'"></kendo-combobox>
        </div>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-6">
        <div>ทางหลวง :</div>
        <div class="d-flex align-items-center">
            <kendo-combobox class="form-control" [data]="route_list" [allowCustom]="allowCustom" 
            [suggest]="true" [(ngModel)]="search_route"[filterable]="true" [placeholder]="'ทั้งหมด'" 
            (filterChange)="FilterRoute($event)"  (valueChange)="ChageRoute($event)"></kendo-combobox>
        </div>
    </div>

    <div class="col-lg-4 col-md-4 col-sm-6">
        <div>แหล่งที่มา :</div>
        <div class="d-flex align-items-center">
            <select [(ngModel)]="src_type" name="src_type" id="" class="form-control">
                <option value=null>Select</option>
                <option value=1>จส100</option>
                <option value=2>Line</option>
                <option value=3>Facebook</option>
                <option value=4>สวพ91</option>
                <option value=5>Twitter</option>
                <option value=6>จนท.ในพื้นที่</option>
                <option value=7>ข้อมูลจากส่วนกลาง</option>
                <option value=8>อื่นๆ</option>
            </select>
        </div>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-6 mt-2">
        <div>หลักกิโลเมตร :</div>
        <div>
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">เริ่มต้น</span>
                </div>
                <input type="text" class="form-control" [(ngModel)]="st_km">
                <div class="input-group-prepend">
                    <span class="input-group-text">สิ้นสุด</span>
                </div>
                <input type="text" class="form-control" [(ngModel)]="en_km">
            </div>
        </div>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-6 mt-2">
        <div>วันที่ :</div>
        <div class="input-group" style="flex-wrap: inherit;">
            <div class="input-group-prepend">
                <span class="input-group-text">เริ่ม</span>
                <kendo-datepicker style="max-width: 300px;" [(ngModel)]="st_date" class="w-100"
                    [format]="'dd-MMM-yyyy'"></kendo-datepicker>
            </div>
            <div class="input-group-prepend">
                <span class="input-group-text">ถึง</span>
                <kendo-datepicker style="max-width: 300px;" [(ngModel)]="en_date" class="w-100"
                    [format]="'dd-MMM-yyyy'"></kendo-datepicker>
            </div>

        </div>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-3 mt-2">
        <div>
            <p>&emsp;</p>
        </div>
        <input class="ml-2" type="checkbox" name="pvr" id="p1" [(ngModel)]="direction[0]">
        <label style="margin-top: 5px;" class="ml-2" for="p1">
            ขาเข้า
        </label>
        <input class="ml-2" type="checkbox" name="pvr" id="p2" [(ngModel)]="direction[1]">
        <label style="margin-top: 5px;" class="ml-2" for="p2">
            ขาออก
        </label>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-3 mt-2 d-flex justify-content-end align-items-center">
        <button type="button" (click)="search()" class="btn btn-primary pull-right text-white"> <i
                class="fa fa-search"></i>
            ค้นหา </button>
    </div>

    <div class="col-12 mt-3 table-header pl-3 pr-3 pt-2 pb-2 d-flex align-items-center justify-content-between">
        <div>Incident</div>
        <button *ngIf="isAdd" class="btn btn-info text-white" (click)="openModal()">
            + เพิ่ม Incident
        </button>

    </div>


    <div class="col-12 mt-3">
        <table class="table-main table table-outline mb-0">
            <thead class="thead-dark" style="background-color: black;">
                <tr>
                    <th class="text-center text-light table-header"><i class="fa fa-hashtag"></i></th>
                    <th class="text-center text-light table-header">ประเภทเหตุการณ์</th>
                    <th class="text-center text-light table-header">ที่มา</th>
                    <th class="text-center text-light table-header">ตำแหน่ง</th>
                    <th class="text-center text-light table-header">Create date</th>
                    <th *ngIf="isDetail || isDelete" class="text-center text-light table-header">Action</th>
                </tr>
            </thead>
            <tbody *ngIf="!data_list || data_list?.length < 1">
                <tr>
                    <td colspan="10" style="height: 50px;">
                        ไม่พบข้อมูล
                    </td>
                </tr>
            </tbody>
            <tbody  *ngIf="data_list?.length">
                <tr *ngFor="let i of data_list | slice: (currentPage-1) * itemsPerPage : (currentPage-1) * itemsPerPage + itemsPerPage;index as idx">
                    <td>
                        {{(idx+1)+(itemsPerPage*(currentPage-1))}}
                    </td>
                    <td>{{(i.occur_type_obj)? i.occur_type_obj.name:''}}</td>
                    <td [ngSwitch]="i.src_type">
                        <p class="m-0" *ngSwitchCase="1">จส100</p>
                        <p class="m-0" *ngSwitchCase="2">Line</p>
                        <p class="m-0" *ngSwitchCase="3">Facebook</p>
                        <p class="m-0" *ngSwitchCase="4">สวพ91</p>
                        <p class="m-0" *ngSwitchCase="5">Twitter</p>
                        <p class="m-0" *ngSwitchCase="6">จนท.ในพื้นที่</p>
                        <p class="m-0" *ngSwitchCase="7">ข้อมูลจากส่วนกลาง</p>
                        <p class="m-0" *ngSwitchCase="8">อื่นๆ ({{i.src_type_etc||''}})</p>
                        <p class="m-0" *ngSwitchDefault></p>
                    </td>
                    <td>
                        {{(i.location.point1&&i.location.point1.dir==1)? 'ขาเข้า':''}}
                        {{(i.location.point1&&i.location.point1.dir==2)? ' ขาออก':''}}
                        {{(i.location.point1&&i.location.point1.dir==3)? 'ขาเข้า-ขาออก':''}}
                        {{(i.location.point1)? i.location.point1.route +' ('+i.location.point1.km+' + '+i.location.point1.m+')':''}}
                        
                        {{(i.location.point2&&i.location.point2.dir==1)? '/ ขาเข้า':''}}
                        {{(i.location.point2&&i.location.point2.dir==2)? '/ ขาออก':''}}
                        {{(i.location.point2&&i.location.point2.dir==3)? '/ ขาเข้า-ขาออก':''}}
                        {{(i.location.point2)? i.location.point2.route +' ('+i.location.point2.km+' + '+i.location.point2.m+')':''}}
                    </td>
                    <td>{{i.create_date | date:'dd/MM/yyyy HH:mm:ss':'+0700'}}</td>
                    <td *ngIf="isDetail || isDelete">
                        <button *ngIf="isDetail" class="ml-1 mr-1 btn btn-info" (click)="openModal(i)">
                            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                        </button>
                        <button *ngIf="isDelete" class="ml-1 mr-1 btn btn-danger" (click)="rmlist(i.id)">
                            <i class="fa fa-trash" aria-hidden="true"></i>
                        </button>
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="table-footer">
                        <div class="table-footer-inside d-flex align-items-center justify-content-between">
                            <div *ngIf="data_list.length" class="ml-3 pt-3">

                                <pagination [(ngModel)]="currentPage" [totalItems]="data_list.length"
                                    [itemsPerPage]="itemsPerPage" (pageChanged)="pageChanged($event)"
                                    previousText="&lsaquo;" nextText="&rsaquo;" [boundaryLinks]="true"
                                    firstText="&laquo;" lastText="&raquo;">></pagination>

                            </div>
                            <div></div>
                            <div class="mr-3 ml-2">total: {{data_list.length||0}}</div>

                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

</div>