<div class="modal-header menu-BG">
    <button *ngIf="isEditDetail" class="btn menu-BT" [ngClass]="{'active': chkMenu == 1}" (click)="chkMenu = 1">รายละเอียดเหตุการณ์</button>
    <button *ngIf="isActivityLog && values" class="btn menu-BT" [ngClass]="{'active': chkMenu == 2}" (click)="chkMenu = 2">Activity Log</button>
    <button *ngIf="isNews" class="btn menu-BT" [ngClass]="{'active': chkMenu == 3}" (click)="chkMenu = 3">ข่าวสาร</button>
    <button *ngIf="isSignControl && values" class="btn menu-BT" [ngClass]="{'active': chkMenu == 4}" (click)="chkMenu = 4;gotoSign()">Sign Control</button>
</div>
<div *ngIf="chkMenu == 3" class="modal-body text-center">
    News
</div>
<!-- 2=========== -->
<div *ngIf="chkMenu == 2" class="modal-body text-center">
    <div class="row">
        <div class="col-8 text-left blog-BG">
            <div *ngFor="let i of act_list;index as idx" class="d-flex log-BG mb-3">
                <p class="m-0">{{i.create_date | date:'dd/MM/yyyy':'+0700'}}<br>{{i.create_date | date:'HH:mm':'+0700'}}น.</p>
                <div class="position-relative ml-3 mr-3">
                    <div *ngIf="idx!==act_list.length-1" class="line-BG"></div>
                    <div class="sta-BG"></div>
                    <div class="position-absolute sta-BG done"[ngClass]="{
                        'reject': i.status == 5,
                        'create': i.status == 1,
                        'operate': i.status == 3,
                        'done': i.status == 4
                    }" style="top:0px"></div>
                </div>
                <div class="msg-BG">
                    <p>{{i.desc}}</p>
                    <div *ngIf="i.image.length">
                        <img *ngFor="let j of i.image" [src]="j" alt="" style="max-height: 50px;">
                    </div>
                </div>
                <div class="name-BG"><p>{{i.create_by||''}}</p></div>
            </div>

        </div>
        <div class="col-4">
            <div class="card">
                <div class="card-header">
                    ขั้นตอนการปฏิบัติการสำหรับเจ้าหน้าที่
                </div>
                <div class="card-body">
                   <div class="contact-BG" (click)="contact_sta = 1;msg='ติดต่อการไฟฟ้า'">
                       ติดต่อการไฟฟ้า
                       <i [ngClass]="(contact_sta&&contact_sta==1)?'clicked':'out'" class="fa fa-chevron-down" aria-hidden="true"></i>
                   </div>
                   <div class="contact-BG" (click)="contact_sta = 2;msg='ติดต่อสถานีดับเพลิง'">
                       ติดต่อสถานีดับเพลิง
                       <i [ngClass]="(contact_sta&&contact_sta==2)?'clicked':'out'" class="fa fa-chevron-down" aria-hidden="true"></i>
                   </div>
                   <div class="contact-BG" (click)="contact_sta = 3;msg='เจ้าหน้าที่ช่วยเหลือ'">
                       เจ้าหน้าที่ช่วยเหลือ
                       <i [ngClass]="(contact_sta&&contact_sta==3)?'clicked':'out'" class="fa fa-chevron-down" aria-hidden="true"></i>
                   </div>
                   <div class="contact-BG" (click)="contact_sta = 4;msg='การจราจรปกติ'">
                       การจราจรปกติ
                       <i [ngClass]="(contact_sta&&contact_sta==4)?'clicked':'out'" class="fa fa-chevron-down" aria-hidden="true"></i>
                   </div>
                </div>
            </div>
        </div>
        <div class="col-8">
            <div class="input-group mb-3 mt-3">
                <!-- <div class="input-group-prepend">
                    <p class="input-group-text">รูปภาพประกอบ</p>
                </div> -->
                <div *ngFor="let i of images" class="addimg-BG position-relative">
                    <img [src]="i" alt="Image" style="max-height: 60px;">
                    <button (click)="rmImage(i)">X</button>
                </div>
                <input type="file" name="" id="addImage" accept="image/*" (change)="uploadFile(Images)" onerror="this.src='assets/noimage.png'" #Images>
                <!-- onerror="this.src='assets/noimage.png'" -->
                <Label for="addImage" class="ml-3 mb-0 btn btn-info addImg-BT">
                    <i class="icon-plus"></i>
                </Label>
            </div>
            <div class="input-group">
                <input type="text" class="form-control" [(ngModel)]="msg">
                <div class="input-group-prepend">
                    <button class="btn-info btn" [disabled]="!msg ||!actlog_status || !isActivityLogManageInc" (click)="sendMsg()">
                        <i class="fa fa-paper-plane mr-2"></i>    
                        Send
                    </button>
                </div>
                <select name="" id="" class="form-control" style="max-width:150px;margin-left: 5px;" [(ngModel)]="actlog_status">
                    <option value=null selected disabled>Please select activity type</option>
                    <option value=1>Created</option>
                    <option value=2>Approved</option>
                    <option value=3>Operating</option>
                    <option value=4>Clear</option>
                    <option value=5>Rejected</option>
                </select>
            </div>
        </div>
        <div class="col-4">
        </div>
        <div class="col-4 status-BG position-relative d-flex justify-content-between">
            <div><p>Reject</p></div>
            <div><p>Create</p></div>
            <div><p>Operate</p></div>
            <div><p>Close</p></div>
            <div></div>
        </div>
    </div>
</div>

<div class="modal-footer" *ngIf="chkMenu == 2">
    <button type="button" class="btn btn-danger" (click)="close()">ยกเลิก</button>
</div>
<!-- 1=========== -->
<div *ngIf="chkMenu == 1" class="modal-body text-center">
    <div class="row">
        <div class="col-12">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text">ชื่อเหตุการณ์ <span class="red-txt"> *</span></p>
                </div>
                <input type="text" class="form-control" [(ngModel)]="name" [disabled]="disabled">
            </div>
        </div>
        <div class="col-12">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text">รายละเอียด</p>
                </div>
                <textarea name="detail" id="" cols="30" rows="3" [(ngModel)]="desc" class="form-control" style="resize: none;" [disabled]="disabled"></textarea>
            </div>
        </div>
        <div class="col-12">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text">ที่มา</p>
                </div>
                <!-- <p class="input-group-text">ที่มา</p> -->
                <label class="ml-3 src">
                    <input type="radio" name="source" id="" [value]="1" [(ngModel)]="src_type" [disabled]="disabled && src_type!==1">
                    จส100
                </label>
                <label class="ml-3 src">
                    <input type="radio" name="source" id="" [value]="2" [(ngModel)]="src_type" [disabled]="disabled && src_type!==2">
                    Line
                </label>
                <label class="ml-3 src">
                    <input type="radio" name="source" id="" [value]="3" [(ngModel)]="src_type" [disabled]="disabled && src_type!==3">
                    Facebook
                </label>
                <label class="ml-3 src">
                    <input type="radio" name="source" id="" [value]="4" [(ngModel)]="src_type" [disabled]="disabled && src_type!==4">
                    สวพ91
                </label>
                <label class="ml-3 src">
                    <input type="radio" name="source" id="" [value]="5" [(ngModel)]="src_type" [disabled]="disabled && src_type!==5">
                    Twitter
                </label>
                <label class="ml-3 src">
                    <input type="radio" name="source" id="" [value]="6" [(ngModel)]="src_type" [disabled]="disabled && src_type!==6">
                    จนท.ในพื้นที่
                </label>
                <label class="ml-3 src">
                    <input type="radio" name="source" id="" [value]="7" [(ngModel)]="src_type" [disabled]="disabled && src_type!==7">
                    ข้อมูลจากส่วนกลาง
                </label>
                <label class="ml-3 src">
                    <input type="radio" name="source" id="" [value]="8" [(ngModel)]="src_type" [disabled]="disabled && src_type!==8">
                    อื่นๆ
                </label>
                
                <input type="text" [(ngModel)]="src_type_etc" class="form-control" [disabled]="disabled">
            </div>
        </div>
        <div class="col-12">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text">ประเภทเหตุการณ์</p>
                </div>

                <kendo-combobox class="form-control" [data]="inc_type" [allowCustom]="allowCustom" [suggest]="true" [textField]="'name'" [valueField]="'id'" [value]="incident_type"[filterable]="true" [placeholder]="'ทั้งหมด'" (valueChange)="changeIncType($event)" 
            (filterChange)="FilterType($event)" [disabled]="disabled"></kendo-combobox>
            </div>
        </div>
        <div class="col-12">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text">เลือกพื้นที่การเกิดเหตุ<span class="red-txt"> *</span></p>
                </div>
                
                <select name="" id="" class="form-control" [(ngModel)]="asp_type" (change)="aspChange()" [disabled]="disabled">
                    <option value="1">แบบจุด</option>
                    <option value="2">แบบเส้น</option>
                </select>
                <label class="ml-3 src">
                    <input type="radio" name="dir1" id="" [value]="1" [(ngModel)]="dir1" [disabled]="disabled && (dir1!==1)">
                    ขาเข้า
                </label>
                <label class="ml-3 src">
                    <input type="radio" name="dir1" id="" [value]="2" [(ngModel)]="dir1" [disabled]="disabled && (dir1!==2)">
                    ขาออก
                </label>
                <label class="ml-3 src">
                    <input type="radio" name="dir1" id="" [value]="3" [(ngModel)]="dir1" [disabled]="disabled && (dir1!==3)">
                    ขาเข้า-ขาออก
                </label>
            </div>
        </div>
        <div class="col-12">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text">หมายเลขทางหลวง</p>
                </div>
                <kendo-combobox class="form-control" [data]="route_list" [allowCustom]="allowCustom" 
                [suggest]="true" [(ngModel)]="search_route"[filterable]="true" [placeholder]="'ทั้งหมด'" 
                (filterChange)="FilterRoute($event)"  (valueChange)="ChageRoute($event)" [disabled]="disabled"></kendo-combobox>
                <div class="input-group-prepend">
                    <p class="input-group-text">กิโลเมตร</p>
                </div>
                <input type="text" [(ngModel)]="km1" class="form-control" [disabled]="disabled">
                <div class="input-group-prepend">
                    <p class="input-group-text">เมตร</p>
                </div>
                <input type="text" [(ngModel)]="m1" class="form-control" [disabled]="disabled">
            </div>
        </div>
        <div *ngIf="asp_type == 2" class="col-12">
            <div class="input-group mb-3">
                
                <div class="input-group-prepend">
                    <p class="input-group-text">พื้นที่เกิดเหตุปลายทาง</p>
                </div>
                <label class="ml-3 src">
                    <input type="radio" name="dir2" id="" [value]="1" [(ngModel)]="dir2" [disabled]="disabled && (dir2!==1)">
                    ขาเข้า
                </label>
                <label class="ml-3 src">
                    <input type="radio" name="dir2" id="" [value]="2" [(ngModel)]="dir2" [disabled]="disabled && (dir2!==2)">
                    ขาออก
                </label>
                <label class="ml-3 src">
                    <input type="radio" name="dir2" id="" [value]="3" [(ngModel)]="dir2" [disabled]="disabled && (dir2!==3)">
                    ขาเข้า-ขาออก
                </label>
            </div>
        </div>
        <div *ngIf="asp_type == 2" class="col-12">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text">หมายเลขทางหลวง</p>
                </div>
                <kendo-combobox class="form-control" [data]="route_list" [allowCustom]="allowCustom" 
                [suggest]="true" [(ngModel)]="search_route2"[filterable]="true" [placeholder]="'ทั้งหมด'" 
                (filterChange)="FilterRoute($event)"  (valueChange)="ChageRoute($event)" [disabled]="disabled"></kendo-combobox>
                <div class="input-group-prepend">
                    <p class="input-group-text">กิโลเมตร</p>
                </div>
                <input type="text" [(ngModel)]="km2" class="form-control" [disabled]="disabled">
                <div class="input-group-prepend">
                    <p class="input-group-text">เมตร</p>
                </div>
                <input type="text" [(ngModel)]="m2" class="form-control" [disabled]="disabled">
            </div>
        </div>
        <div class="col-12">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text">จำนวนผู้บาดเจ็บ</p>
                </div>
                <input type="text" class="form-control" [(ngModel)]="dmg_human_dead" [disabled]="disabled">
                <div class="input-group-prepend">
                    <p class="input-group-text">จำนวนผู้เสียชีวิต</p>
                </div>
                <input type="text" class="form-control" [(ngModel)]="dmg_human_inj" [disabled]="disabled">
                <div class="input-group-prepend">
                    <p class="input-group-text">จำนวนรถที่เกิดเหตุ</p>
                </div>
                <input type="text" class="form-control" [(ngModel)]="dmg_vehicle_dmg" [disabled]="disabled">
            </div>
        </div>
        <div class="col-12">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text">ประเถทรถที่เกิดเหตุ</p>
                </div>
                <label class="ml-3 src">
                    <input type="checkbox" name="type" id="" [value]="1" [(ngModel)]="veh_dmg_list[1]" [disabled]="disabled">
                    จักรยาน
                </label>
                <label class="ml-3 src">
                    <input type="checkbox" name="type" id="" [value]="2" [(ngModel)]="veh_dmg_list[2]" [disabled]="disabled">
                    รถยนต์ส่วนบุคคลทั่วไป
                </label>
                <label class="ml-3 src">
                    <input type="checkbox" name="type" id="" [value]="3" [(ngModel)]="veh_dmg_list[3]" [disabled]="disabled">
                    รถสามล้อหรือสามล้อเครื่อง
                </label>
                <label class="ml-3 src">
                    <input type="checkbox" name="type" id="" [value]="4" [(ngModel)]="veh_dmg_list[4]" [disabled]="disabled">
                    รถบรรทุกมีคอนพ่วง
                </label>
                <label class="ml-3 src">
                    <input type="checkbox" name="type" id="" [value]="5" [(ngModel)]="veh_dmg_list[5]" [disabled]="disabled">
                    รถกระบะ
                </label>
                <label class="ml-3 src">
                    <input type="checkbox" name="type" id="" [value]="6" [(ngModel)]="veh_dmg_list[6]" [disabled]="disabled">
                    จักรยานยนต์
                </label>
                <label class="ml-3 src">
                    <input type="checkbox" name="type" id="" [value]="7" [(ngModel)]="veh_dmg_list[7]" [disabled]="disabled">
                    รถบรรทุก
                </label>
                <label class="ml-3 src">
                    <input type="checkbox" name="type" id="" [value]="8" [(ngModel)]="veh_dmg_list[8]" [disabled]="disabled">
                    รถบรรทุกมีวัตถุอันตราย
                </label>
                <label class="ml-3 src">
                    <input type="checkbox" name="type" id="" [value]="9" [(ngModel)]="veh_dmg_list[9]" [disabled]="disabled">
                    อื่นๆ
                </label>
                
                <input type="text" [(ngModel)]="veh_dmg_etc" class="form-control" [disabled]="disabled">
            </div>
        </div>
        <div class="col-12">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text">รูปภาพประกอบ</p>
                </div>
                
                <div *ngFor="let i of images_incdetail" class="addimg-BG position-relative">
                    <img [src]="i" alt="images_incdetail" style="max-height: 60px;">
                    <button *ngIf="!disabled" (click)="rmImage(i)">X</button>
                </div>
                <input type="file" name="" id="addImage" accept="image/*" (change)="uploadFile(ImagesInc,1)" onerror="this.src='assets/noimage.png'" #ImagesInc>
                <!-- onerror="this.src='assets/noimage.png'" -->
                <Label *ngIf="!disabled" for="addImage" class="ml-3 mb-0 btn btn-info addImg-BT">
                    <i class="icon-plus"></i>
                </Label>
            </div>
        </div>
        <div class="col-12">
            <div class="card">
                <div class="card-header menu-BG d-flex">
                    <button class="menu-BT" [ngClass]="{'active': dirBT == 1}" (click)="dirBT = 1">
                        ขาเข้า
                    </button>
                    <button class="menu-BT" [ngClass]="{'active': dirBT == 2}" (click)="dirBT = 2">
                        ขาออก
                    </button>
                </div>
                <div class="card-body">
                    <div class="col-12 text-left">
                        <p>พื้นที่ปิดช่องทาง</p>
                    </div>
                    <div *ngIf="dirBT==1">
                        <div *ngFor="let i of close_lane_in" class="col-12">
                            <div class="input-group mb-3 lane-BG">
                                <input type="checkbox" name="lane" [id]="'in_'+i.name+i.id" [value]="i.id" [(ngModel)]="i.checked" [disabled]="disabled">
                                <label class="pointer" [for]="'in_'+i.name+i.id">{{i.name}}</label>
                                <div class="input-group-prepend">
                                    <p class="input-group-text">เริ่ม</p>
                                </div>
                                <input class="form-control" [(ngModel)]="i.km1" [disabled]="disabled">
                                <div class="input-group-prepend">
                                    <p class="input-group-text">+</p>
                                </div>
                                <input class="form-control" [(ngModel)]="i.m1" [disabled]="disabled">
                                <div class="input-group-prepend">
                                    <p class="input-group-text">สิ้นสุด</p>
                                </div>
                                <input class="form-control" [(ngModel)]="i.km2" [disabled]="disabled">
                                <div class="input-group-prepend">
                                    <p class="input-group-text">+</p>
                                </div>
                                <input class="form-control" [(ngModel)]="i.m2" [disabled]="disabled">
                            </div>
                        </div>
                    </div>
                    <div *ngIf="dirBT==2">
                        <div *ngFor="let j of close_lane_out" class="col-12">
                            <div class="input-group mb-3 lane-BG">
                                <input type="checkbox" name="lane" [id]="'out_'+j.name+j.id" [value]="j.id" [(ngModel)]="j.checked" [disabled]="disabled">
                                <label class="pointer" [for]="'out_'+j.name+j.id">{{j.name}}</label>
                                <div class="input-group-prepend">
                                    <p class="input-group-text">เริ่ม</p>
                                </div>
                                <input class="form-control" [(ngModel)]="j.km1" [disabled]="disabled">
                                <div class="input-group-prepend">
                                    <p class="input-group-text">+</p>
                                </div>
                                <input class="form-control" [(ngModel)]="j.m1" [disabled]="disabled">
                                <div class="input-group-prepend">
                                    <p class="input-group-text">สิ้นสุด</p>
                                </div>
                                <input class="form-control" [(ngModel)]="j.km2" [disabled]="disabled">
                                <div class="input-group-prepend">
                                    <p class="input-group-text">+</p>
                                </div>
                                <input class="form-control" [(ngModel)]="j.m2" [disabled]="disabled">
                            </div>
                        </div>
                    </div>
                    <div *ngIf="dirBT==1" class="col-12">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <p class="input-group-text">Start DateTime</p>
                            </div>
                            <kendo-datetimepicker [(ngModel)]="st_date_in" class="form-control" [format]="'dd-MMM-yyyy HH:mm'" [disabled]="disabled"></kendo-datetimepicker>
                        </div>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <p class="input-group-text">End DateTime</p>
                            </div>
                            <kendo-datetimepicker [(ngModel)]="en_date_in" class="form-control" [format]="'dd-MMM-yyyy HH:mm'" [disabled]="disabled"></kendo-datetimepicker>
                        </div>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <p class="input-group-text">แจ้งเตือนล่วงหน้า DateTime</p>
                            </div>
                            <kendo-datetimepicker [(ngModel)]="noti_date_in" class="form-control" [format]="'dd-MMM-yyyy HH:mm'" [disabled]="disabled"></kendo-datetimepicker>
                        </div>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <p class="input-group-text">รายละเอียดการแจ้งเตือน</p>
                            </div>
                            <input type="text" class="form-control"  [(ngModel)]="noti_message_in" [disabled]="disabled">
                        </div>
                    </div>
                    <div *ngIf="dirBT==2" class="col-12">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <p class="input-group-text">Start DateTime</p>
                            </div>
                            <kendo-datetimepicker [(ngModel)]="st_date_out" class="form-control" [format]="'dd-MMM-yyyy HH:mm'" [disabled]="disabled"></kendo-datetimepicker>
                        </div>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <p class="input-group-text">End DateTime</p>
                            </div>
                            <kendo-datetimepicker [(ngModel)]="en_date_out" class="form-control" [format]="'dd-MMM-yyyy HH:mm'" [disabled]="disabled"></kendo-datetimepicker>
                        </div>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <p class="input-group-text">แจ้งเตือนล่วงหน้า DateTime</p>
                            </div>
                            <kendo-datetimepicker [(ngModel)]="noti_date_out" class="form-control" [format]="'dd-MMM-yyyy HH:mm'" [disabled]="disabled"></kendo-datetimepicker>
                        </div>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <p class="input-group-text">รายละเอียดการแจ้งเตือน</p>
                            </div>
                            <input type="text" class="form-control"  [(ngModel)]="noti_message_out" [disabled]="disabled">
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="col-12 text-left">
            เลือกพิกัด 
            <!-- <p>
                Point1 {{lat | number:'1.2-6'}} {{lng | number:'1.2-6'}}
            </p>
            <p>
                Point2 {{lat2 | number:'1.2-6'}} {{lng2 | number:'1.2-6'}}
            </p> -->
            <div *ngIf="asp_type == 2 && !disabled">
                <input type="radio" name="mk_BT" id="mk1" [value]=1 [(ngModel)]="mk_BT"><label for="mk1" class="mr-3"> Point1</label>
                <input type="radio" name="mk_BT" id="mk2" [value]=2 [(ngModel)]="mk_BT"><label for="mk2"> Point2</label>
            </div>
            <div class="map" id="map-incident" #mapIncident></div>
            <!-- <pre id="coordinates" class="coordinates"></pre> -->
        </div>
        <div class="col-4 status-BG position-relative d-flex justify-content-between">
            <div><p>Reject</p></div>
            <div><p>Create</p></div>
            <div><p>Operate</p></div>
            <div><p>Close</p></div>
            <div></div>
        </div>
    </div>
</div>
<div class="modal-footer" *ngIf="chkMenu == 1">
    <button type="button" class="btn btn-danger" (click)="close()">ปิด</button>
    <button *ngIf="isManageInc" type="button" class="btn btn-info" [disabled]="(!name || !search_route)? 'disabled': null" (click)="save()">ยืนยัน</button>
</div>