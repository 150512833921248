import { NgModule } from '@angular/core';
import { ExtraOptions, Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';
import { AuthGuard } from './services/auth/auth.guard';
import { LoginComponent } from './views/login/login.component';
export let routes: Routes = [
  {
    path: '',
    redirectTo: 'assets-management',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'sign-control',
        loadChildren: () => import('./views/sign-control/sign-control.module').then(m => m.SignControlModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'assets-management',
        loadChildren: () => import('./views/assets-management/assets-management.module').then(m => m.AssetsManagementModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'user-management',
        loadChildren: () => import('./views/user-management/user-management.module').then(m => m.UserManagementModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'traffictool',
        // component : SmartmicroComponent
        loadChildren: () => import('./views/traffic-tool/traffic-tool.module').then(m => m.TrafficToolModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'incident',
        loadChildren: () => import('./views/incident/incident.module').then(m => m.IncidentModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'information',
        loadChildren: () => import('./views/information/information.module').then(m => m.InformationModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'corridor-control',
        loadChildren: () => import('./views/corridor-control/corridor-control.module').then(m => m.CorridorControlModule),
        canActivate: [AuthGuard]
      }
    ]
  }
];

const config: ExtraOptions = {
  useHash: false,
};
@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
