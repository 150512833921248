import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash'
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FuncService } from 'src/app/services/user/func.service';
import { IncidentService } from 'src/app/services/user/incident.service';
import { IncidentTypeModalComponent } from './incident-type-modal/incident-type-modal.component';
import { CommonService } from 'src/app/services/common.service';
import { permission } from 'src/app/views/incident/menu';
@Component({
  selector: 'app-incident-type',
  templateUrl: './incident-type.component.html',
  styleUrls: ['./incident-type.component.scss']
})
export class IncidentTypeComponent implements OnInit {
  modalRef: BsModalRef;
  allowCustom: boolean = false;
  allData : any = []
  route_list : any
  search_txt : any
  direction : any = [true,true]
  st_date : any
  en_date : any
  data_list : any = []
  allType : any
  list: any;
  total : number = 0;
  currentPage : number = 1;
  itemsPerPage : number = 15;
  
  isAdd: boolean = false;
  isEdit: boolean = false;
  constructor(
    private func: FuncService,
    private modalService: BsModalService,
    private inc_S: IncidentService,
    private commonService: CommonService
  ) { }

  async ngOnInit() {
    this.isAdd = await this.commonService.getPermission(permission.incident_type.add_id).toPromise();
    this.isEdit = await this.commonService.getPermission(permission.incident_type.edit_id).toPromise();
    this.getData()
  }

  handleFilter(value) {
    if (value.length > 0) {
     this.allData = this.allType.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.allData = _.cloneDeep(this.allType);
    } else {
     this.allData = [];
    }
  }
  handleValue(value) {
    if (value) {
      this.search_txt = value;
    } else {
      this.search_txt = null;
    }
  }
  async search(){
    let data : any = {}
    if(this.search_txt&&this.search_txt.name) data.name = this.search_txt.name || null
    this.data_list = await this.inc_S.getIncidentType(data)
  }
  async rmlist(id) {
    let result = await this.func.alertpopup('<h4>คุณต้องการลบใช่หรือไม่</h4>', `<i class="icon-info mr-2"></i>ลบผู้ใช้งาน`, 1);
    if (result) {
      let res: any = await this.inc_S.rmIncidentType(id)
      if (res) this.getData()
    }
  }
  async getData(){
    this.data_list = await this.inc_S.getIncidentType()
    this.allData = this.data_list
    this.allType = this.data_list
  }
  openModal(data?: any) {
    const initialState = {
      values: data,
      list: this.list
    };
    this.modalRef = this.modalService.show(IncidentTypeModalComponent, { initialState });
    this.modalRef.content.event.subscribe(data => {
      if (data) this.getData()
      // return resolve(data);
    });
  }
  pageChanged(event: any): void {
    if (this.currentPage != event.page) {
      this.currentPage = event.page;
      // this.getDevice();
    }
  }
}
