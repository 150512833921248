<div class="row p-3">
    
    <div class="col-lg-2 col-md-2 col-sm-3 mt-2 d-flex justify-content-end align-items-center"></div>
    <div class="col-lg-4 col-md-4 col-sm-6">
        <div>ชื่อประเภทเหตุการณ์ :</div>
        <div class="d-flex align-items-center">
            <kendo-combobox class="w-100" [data]="allData" [allowCustom]="allowCustom" [suggest]="true" [textField]="'name'" [valueField]="'name'" [value]="search_txt" (valueChange)="handleValue($event)" [filterable]="true"
                (filterChange)="handleFilter($event)" [placeholder]="'ทั้งหมด'"></kendo-combobox>
        </div>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-3 mt-2 d-flex justify-content-end align-items-center">
        <button type="button" (click)="search()" class="btn btn-primary pull-right text-white"> <i
                class="fa fa-search"></i>
            ค้นหา </button>
    </div>

    <div class="col-12 mt-3 table-header pl-3 pr-3 pt-2 pb-2 d-flex align-items-center justify-content-between">
        <div>Incident Type</div>
        <button *ngIf="isAdd" class="btn btn-info text-white" (click)="openModal()">
            + เพิ่มประเภทอุปกรณ์
        </button>
    </div>


    <div class="col-12 mt-3">
        <table class="table-main table table-outline mb-0">
            <thead class="thead-dark" style="background-color: black;">
                <tr>
                    <th class="text-center text-light table-header"><i class="fa fa-hashtag"></i></th>
                    <th class="text-center text-light table-header">ชื่อประเภทเหตุการณ์</th>
                    <!-- <th class="text-center text-light table-header">ประเภทเหตุการณ์</th> -->
                    <th class="text-center text-light table-header">สัญลักษณ์</th>
                    <th *ngIf="isEdit" class="text-center text-light table-header">Action</th>
                </tr>
            </thead>
            <tbody>

                <tr *ngIf="!data_list || data_list?.length < 1">
                    <td colspan="10" style="height: 50px;">
                        ไม่พบข้อมูล
                    </td>
                </tr>
                <tr *ngFor="let i of data_list | slice: (currentPage-1) * itemsPerPage : (currentPage-1) * itemsPerPage + itemsPerPage;index as idx">
                    <td>
                        {{(idx+1)+(itemsPerPage*(currentPage-1))}}
                    </td>
                    <td>{{i.name||''}}</td>
                    <td>
                        <!-- {{(i.icon_type==1)? 'Image (URL)':''}}
                        {{(i.icon_type==2)? 'Image (Base64)':''}}
                        {{(i.icon_type==3)? 'Font Awesome':''}} -->
                        
                        <img *ngIf="i.icon_type < 3" [src]="i.icon" alt="" height="50">
                        <i *ngIf="i.icon_type==3" class="{{i.icon||'icon-close'}}" [ngStyle]="{'color': i.icon_color}"></i>
                    </td>
                    <!-- <td>{{i.create_date | date:'dd/MM/yyyy HH:mm:ss':'+0700'}}</td> -->
                    <td *ngIf="isEdit">
                        <button class="ml-1 mr-1 btn btn-warning" (click)="openModal(i)">
                            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                        </button>
                        <button class="ml-1 mr-1 btn btn-danger" (click)="rmlist(i.id)">
                            <i class="fa fa-trash" aria-hidden="true"></i>
                        </button>
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="table-footer">
                        <div class="table-footer-inside d-flex align-items-center justify-content-between">
                            <div class="ml-3 pt-3">
                                <pagination [(ngModel)]="currentPage" [totalItems]="data_list.length"
                                [itemsPerPage]="itemsPerPage" (pageChanged)="pageChanged($event)" previousText="&lsaquo;" nextText="&rsaquo;" [boundaryLinks]="true"
                                firstText="&laquo;" lastText="&raquo;"></pagination>    

                            </div>
                            <div></div>
                            <div class="mr-3 ml-2">total: {{data_list.length||0}}</div>

                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

</div>