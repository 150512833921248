<div class="animated fadeIn m-4">
  <div class="row">
    <div class="col-md-12 col-lg-12" [ngClass]="(view == 1)?'col-xl-5':'col-xl-12 order-2'">
      <div class="bg-gray-850">
        <div class="position-relative">
          <div
            class="col-12 bg-primary pl-3 pr-2 pt-2 pb-2 d-flex align-items-center justify-content-between position-absolute">
            <div>
            </div>
            <kendo-combobox style="width: 200px;" [data]="station_search_list" [allowCustom]="allowCustom"
              [textField]="'name'" [valueField]="'id'" [value]="search_station"
              (valueChange)="handleValueStation($event)" (filterChange)="handleFilterStation($event)"
              [placeholder]="'โปรดระบุ'" [filterable]="true">
            </kendo-combobox>
          </div>
          <div class="position-absolute mt-3">
            <div class="bg-gray-1000 pt-2 pb-2 pl-4 pr-4">
              <div class="h4 font-3">
                Average Speed
              </div>
            </div>
          </div>
        </div>
        <div style="height: 70px;"></div>
        <div class="row no-gutters">
          <div class="col-6">
            <div class="bg-in pl-3 pr-3 pt-2 pb-2 d-flex align-items-center justify-content-center mb-2">
              <span class="text-black font-3">IN (ขาเข้า)</span>
            </div>
            <div class="row no-gutters m-1">
              <div class="col-sm-6 col-md-6">
                <div class="bg-gray-700 m-1">
                  <kendo-radialgauge [pointer]="[{ value: this.data.normal_speed_in, length: 1.2 }]"
                    [transitions]="false" style="display:block;height:100px;">
                    <kendo-radialgauge-scale [majorTicks]="{ visible: showTicks, color: ticksColor }"
                      [minorTicks]="{ visible: showTicks, color: ticksColor }" [reverse]="reverse"
                      [startAngle]="startAngle" [endAngle]="endAngle" [rangePlaceholderColor]="rangePlaceholderColor"
                      [rangeSize]="rangeSize" [max]="180">
                      <kendo-radialgauge-scale-labels format="c" [color]="labelsColor" [visible]="showLabels">
                      </kendo-radialgauge-scale-labels>
                      <kendo-radialgauge-scale-ranges>
                        <kendo-radialgauge-scale-range [from]="0" [to]="30" color="#b0e277">
                        </kendo-radialgauge-scale-range>
                        <kendo-radialgauge-scale-range [from]="30" [to]="60" color="#94be65">
                        </kendo-radialgauge-scale-range>
                        <kendo-radialgauge-scale-range [from]="60" [to]="90" color="#f6cc75">
                        </kendo-radialgauge-scale-range>
                        <kendo-radialgauge-scale-range [from]="90" [to]="120" color="#dfb96b">
                        </kendo-radialgauge-scale-range>
                        <kendo-radialgauge-scale-range [from]="120" [to]="150" color="#da7666">
                        </kendo-radialgauge-scale-range>
                        <kendo-radialgauge-scale-range [from]="150" [to]="180" color="#c76a5a">
                        </kendo-radialgauge-scale-range>
                      </kendo-radialgauge-scale-ranges>
                    </kendo-radialgauge-scale>
                  </kendo-radialgauge>
                  <div class="d-flex align-items-center justify-content-center" style="margin-top: -20px;">
                    <div>
                      <div *ngIf="this.data.normal_speed_in != null" class="text-light font-2 text-center">
                        {{this.data.normal_speed_in | number:'0.0-2'}}
                      </div>
                      <div *ngIf="this.data.normal_speed_in == null" class="text-light font-2 text-center">
                        -
                      </div>
                      <small class="text-light font-4 mt-2">km/h</small>
                    </div>
                  </div>
                </div>
                <div class="d-flex align-items-center justify-content-center">
                  <small class="text-light font-4">ช่องทางปกติ</small>
                </div>
              </div>
              <div class="col-sm-6 col-md-6">
                <div class="bg-gray-700 m-1">
                  <kendo-radialgauge [pointer]="[{ value: this.data.reverse_speed_in, length: 1.2 }]"
                    [transitions]="false" style="display:block;height:100px;">
                    <kendo-radialgauge-scale [majorTicks]="{ visible: showTicks, color: ticksColor }"
                      [minorTicks]="{ visible: showTicks, color: ticksColor }" [reverse]="reverse"
                      [startAngle]="startAngle" [endAngle]="endAngle" [rangePlaceholderColor]="rangePlaceholderColor"
                      [rangeSize]="rangeSize" [max]="180">
                      <kendo-radialgauge-scale-labels format="c" [color]="labelsColor" [visible]="showLabels">
                      </kendo-radialgauge-scale-labels>
                      <kendo-radialgauge-scale-ranges>
                        <kendo-radialgauge-scale-range [from]="0" [to]="30" color="#b0e277">
                        </kendo-radialgauge-scale-range>
                        <kendo-radialgauge-scale-range [from]="30" [to]="60" color="#94be65">
                        </kendo-radialgauge-scale-range>
                        <kendo-radialgauge-scale-range [from]="60" [to]="90" color="#f6cc75">
                        </kendo-radialgauge-scale-range>
                        <kendo-radialgauge-scale-range [from]="90" [to]="120" color="#dfb96b">
                        </kendo-radialgauge-scale-range>
                        <kendo-radialgauge-scale-range [from]="120" [to]="150" color="#da7666">
                        </kendo-radialgauge-scale-range>
                        <kendo-radialgauge-scale-range [from]="150" [to]="180" color="#c76a5a">
                        </kendo-radialgauge-scale-range>
                      </kendo-radialgauge-scale-ranges>
                    </kendo-radialgauge-scale>
                  </kendo-radialgauge>
                  <div class="d-flex align-items-center justify-content-center" style="margin-top: -20px;">
                    <div>
                      <div *ngIf="this.data.reverse_speed_in != null" class="text-light font-2 text-center">
                        {{this.data.reverse_speed_in | number:'0.0-2'}}
                      </div>
                      <div *ngIf="this.data.reverse_speed_in == null" class="text-light font-2 text-center">
                        -
                      </div>
                      <small class="text-light font-4 mt-2">km/h</small>
                    </div>
                  </div>
                </div>
                <div class="d-flex align-items-center justify-content-center">
                  <small class="text-light font-4">ช่องทางพิเศษ</small>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="bg-out pl-3 pr-3 pt-2 pb-2 d-flex align-items-center justify-content-center mb-2">
              <span class="text-black font-3">OUT (ขาออก)</span>
            </div>
            <div class="row no-gutters m-1">
              <div class="col-sm-6 col-md-6">
                <div class="bg-gray-700 m-1">
                  <kendo-radialgauge [pointer]="[{ value: this.data.normal_speed_out, length: 1.2 }]"
                    [transitions]="false" style="display:block;height:100px;">
                    <kendo-radialgauge-scale [majorTicks]="{ visible: showTicks, color: ticksColor }"
                      [minorTicks]="{ visible: showTicks, color: ticksColor }" [reverse]="reverse"
                      [startAngle]="startAngle" [endAngle]="endAngle" [rangePlaceholderColor]="rangePlaceholderColor"
                      [rangeSize]="rangeSize" [max]="180">
                      <kendo-radialgauge-scale-labels format="c" [color]="labelsColor" [visible]="showLabels">
                      </kendo-radialgauge-scale-labels>
                      <kendo-radialgauge-scale-ranges>
                        <kendo-radialgauge-scale-range [from]="0" [to]="30" color="#b0e277">
                        </kendo-radialgauge-scale-range>
                        <kendo-radialgauge-scale-range [from]="30" [to]="60" color="#94be65">
                        </kendo-radialgauge-scale-range>
                        <kendo-radialgauge-scale-range [from]="60" [to]="90" color="#f6cc75">
                        </kendo-radialgauge-scale-range>
                        <kendo-radialgauge-scale-range [from]="90" [to]="120" color="#dfb96b">
                        </kendo-radialgauge-scale-range>
                        <kendo-radialgauge-scale-range [from]="120" [to]="150" color="#da7666">
                        </kendo-radialgauge-scale-range>
                        <kendo-radialgauge-scale-range [from]="150" [to]="180" color="#c76a5a">
                        </kendo-radialgauge-scale-range>
                      </kendo-radialgauge-scale-ranges>
                    </kendo-radialgauge-scale>
                  </kendo-radialgauge>
                  <div class="d-flex align-items-center justify-content-center" style="margin-top: -20px;">
                    <div>
                      <div *ngIf="this.data.normal_speed_out != null" class="text-light font-2 text-center">
                        {{this.data.normal_speed_out | number:'0.0-2'}}
                      </div>
                      <div *ngIf="this.data.normal_speed_out == null" class="text-light font-2 text-center">
                        -
                      </div>
                      <small class="text-light font-4 mt-2">km/h</small>
                    </div>
                  </div>
                </div>
                <div class="d-flex align-items-center justify-content-center">
                  <small class="text-light font-4">ช่องทางปกติ</small>
                </div>
              </div>
              <div class="col-sm-6 col-md-6">
                <div class="bg-gray-700 m-1">
                  <kendo-radialgauge [pointer]="[{ value: this.data.reverse_speed_out, length: 1.2 }]"
                    [transitions]="false" style="display:block;height:100px;">
                    <kendo-radialgauge-scale [majorTicks]="{ visible: showTicks, color: ticksColor }"
                      [minorTicks]="{ visible: showTicks, color: ticksColor }" [reverse]="reverse"
                      [startAngle]="startAngle" [endAngle]="endAngle" [rangePlaceholderColor]="rangePlaceholderColor"
                      [rangeSize]="rangeSize" [max]="180">
                      <kendo-radialgauge-scale-labels format="c" [color]="labelsColor" [visible]="showLabels">
                      </kendo-radialgauge-scale-labels>
                      <kendo-radialgauge-scale-ranges>
                        <kendo-radialgauge-scale-range [from]="0" [to]="30" color="#b0e277">
                        </kendo-radialgauge-scale-range>
                        <kendo-radialgauge-scale-range [from]="30" [to]="60" color="#94be65">
                        </kendo-radialgauge-scale-range>
                        <kendo-radialgauge-scale-range [from]="60" [to]="90" color="#f6cc75">
                        </kendo-radialgauge-scale-range>
                        <kendo-radialgauge-scale-range [from]="90" [to]="120" color="#dfb96b">
                        </kendo-radialgauge-scale-range>
                        <kendo-radialgauge-scale-range [from]="120" [to]="150" color="#da7666">
                        </kendo-radialgauge-scale-range>
                        <kendo-radialgauge-scale-range [from]="150" [to]="180" color="#c76a5a">
                        </kendo-radialgauge-scale-range>
                      </kendo-radialgauge-scale-ranges>
                    </kendo-radialgauge-scale>
                  </kendo-radialgauge>
                  <div class="d-flex align-items-center justify-content-center" style="margin-top: -20px;">
                    <div>
                      <div *ngIf="this.data.reverse_speed_out != null" class="text-light font-2 text-center">
                        {{this.data.reverse_speed_out | number:'0.0-2'}}
                      </div>
                      <div *ngIf="this.data.reverse_speed_out == null" class="text-light font-2 text-center">
                        -
                      </div>
                      <small class="text-light font-4 mt-2">km/h</small>
                    </div>
                  </div>
                </div>
                <div class="d-flex align-items-center justify-content-center">
                  <small class="text-light font-4">ช่องทางพิเศษ</small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row no-gutters mt-2 mr-2 ml-2 mt-0">
          <div class="col-md-5 col-lg-5 col-xl-4">
            <div class="row no-gutters">
              <div class="col-md-3">
                <div class="card rounded mt-2 mr-0 ml-0 mb-0" style="height: 492px;">
                  <div class="card-body p-0 clearfix bg-in rounded d-flex justify-content-center align-items-center">
                    <div class="text-black font-weight-bold text-center">
                      <div class="h5 font-weight-bold">IN</div>
                      <small>(ขาเข้า)</small>
                    </div>
                  </div>
                </div>
                <div class="card rounded mt-2 mr-0 ml-0 mb-0" style="height: 492px;">
                  <div class="card-body p-0 clearfix bg-out rounded d-flex justify-content-center align-items-center">
                    <div class="text-black font-weight-bold text-center">
                      <div class="h5 font-weight-bold">OUT</div>
                      <small>(ขาออก)</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-9">
                <div class="m-2">
                  <div class="card text-white m-0" style="background-color: #349296;height: 242px;">
                    <div class="card-body">
                      <div class="h1 text-muted text-right">
                        <i class="fa fa-road"></i>
                      </div>
                      <div *ngIf="this.data.normal_flow_in != null" class="h4 mb-0 font-weight-bold">
                        {{this.data.normal_flow_in | number:'0.0-2'}}</div>
                      <div *ngIf="this.data.normal_flow_in == null" class="h4 mb-0 mt-4"></div>
                      <div class="d-flex">
                        <div class="text-muted text-uppercase font-weight-bold">Flow</div> <span
                          class="text-muted ml-1">(Vec/h)</span>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="m-2">
                  <div class="card text-white m-0" style="background-color: #da9693;height: 242px;">
                    <div class="card-body">
                      <div class="h1 text-muted text-right">
                        <i class="fa fa-road"></i>
                      </div>
                      <div *ngIf="this.data.reverse_flow_in != null" class="h4 mb-0 font-weight-bold">
                        {{this.data.reverse_flow_in | number:'0.0-2'}}</div>
                      <div *ngIf="this.data.reverse_flow_in == null" class="h4 mb-0 mt-4"></div>
                      <div class="d-flex">
                        <div class="text-muted text-uppercase font-weight-bold">Flow</div> <span
                          class="text-muted ml-1">(Vec/h)</span>
                      </div>
                      <div class="d-flex justify-content-center mt-2">
                        <div class="font-weight-bold text-black">Reversible Lane</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row no-gutters m-2">
                  <div class="col-sm-12">
                    <div class="card text-white m-0" style="background-color: #349296;height: 242px;">
                      <div class="card-body">
                        <div class="h1 text-muted text-right mb-2">
                          <i class="fa fa-road"></i>
                        </div>
                        <div *ngIf="this.data.normal_flow_out != null" class="h4 mb-0 font-weight-bold">
                          {{this.data.normal_flow_out | number:'0.0-2'}}</div>
                        <div *ngIf="this.data.normal_flow_out == null" class="h4 mb-0 mt-4"></div>
                        <div class="d-flex">
                          <div class="text-muted text-uppercase font-weight-bold">Flow</div> <span
                            class="text-muted ml-1">(Vec/h)</span>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="row no-gutters m-2">
                  <div class="col-sm-12">
                    <div class="card text-white m-0" style="background-color: #da9693;height: 242px;">
                      <div class="card-body">
                        <div class="h1 text-muted text-right">
                          <i class="fa fa-road"></i>
                        </div>
                        <div *ngIf="this.data.reverse_flow_out != null" class="h4 mb-0 font-weight-bold">
                          {{this.data.reverse_flow_out | number:'0.0-2'}}</div>
                        <div *ngIf="this.data.reverse_flow_out == null" class="h4 mb-0 mt-4"></div>
                        <div class="d-flex">
                          <div class="text-muted text-uppercase font-weight-bold">Flow</div> <span
                            class="text-muted ml-1">(Vec/h)</span>
                        </div>
                        <div class="d-flex justify-content-center mt-2">
                          <div class="font-weight-bold text-black">Reversible Lane</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-7 col-lg-7 col-xl-8">
            <div class="row no-gutters">
              <div class="col-sm-12 col-md-12">
                <div class="card text-white bg-gray-800 mt-2 mr-0 ml-0 mb-0">
                  <div class="card-body">
                    <kendo-chart>
                      <kendo-chart-area background="none" [margin]="0" [height]="200">
                      </kendo-chart-area>
                      <kendo-chart-title text="Flow"></kendo-chart-title>
                      <kendo-chart-value-axis>
                        <kendo-chart-value-axis-item [min]="0" [max]="5000">
                        </kendo-chart-value-axis-item>
                      </kendo-chart-value-axis>
                      <kendo-chart-category-axis>
                        <kendo-chart-category-axis-item [categories]="data_categories" [plotBands]="categoryPlotBands"
                          [title]="{ text: '' }">
                        </kendo-chart-category-axis-item>
                      </kendo-chart-category-axis>
                      <kendo-chart-series>

                        <kendo-chart-series-item name="Max" type="line" color="#ff6358" [style]="style"
                          [markers]="markers" [data]="data_normal_flow_in.max" [dashType]="dashType">
                          <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                        </kendo-chart-series-item>
                        <kendo-chart-series-item name="Average" type="line" color="#78d237" [style]="style"
                          [markers]="markers" [data]="data_normal_flow_in.avg" [dashType]="dashType">
                          <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                        </kendo-chart-series-item>
                        <kendo-chart-series-item name="Min" type="line" color="#ffd246" [style]="style"
                          [markers]="markers" [data]="data_normal_flow_in.min" [dashType]="dashType">
                          <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                        </kendo-chart-series-item>
                        <kendo-chart-series-item name="Current" type="line" [style]="style" color="#28b4c8"
                          [markers]="markers" [data]="data_normal_flow_in.current">
                          <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                        </kendo-chart-series-item>
                      </kendo-chart-series>
                      <kendo-chart-legend [position]="'bottom'" [visible]="true"></kendo-chart-legend>
                      <kendo-chart-tooltip [visible]="true"></kendo-chart-tooltip>
                    </kendo-chart>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12">
                <div class="card text-white bg-gray-800 mt-2 mr-0 ml-0 mb-0">
                  <div class="card-body">
                    <kendo-chart>
                      <kendo-chart-area background="none" [margin]="0" [height]="200">
                      </kendo-chart-area>
                      <kendo-chart-title text="Flow"></kendo-chart-title>
                      <kendo-chart-value-axis>
                        <kendo-chart-value-axis-item [min]="0" [max]="5000">
                        </kendo-chart-value-axis-item>
                      </kendo-chart-value-axis>
                      <kendo-chart-category-axis>
                        <kendo-chart-category-axis-item [categories]="data_categories" [plotBands]="categoryPlotBands"
                          [title]="{ text: '' }">
                        </kendo-chart-category-axis-item>
                      </kendo-chart-category-axis>
                      <kendo-chart-series>
                        <kendo-chart-series-item name="Max" type="line" color="#ff6358" [style]="style"
                          [markers]="markers" [data]="data_reverse_flow_in.max" [dashType]="dashType">
                          <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                        </kendo-chart-series-item>
                        <kendo-chart-series-item name="Average" type="line" color="#78d237" [style]="style"
                          [markers]="markers" [data]="data_reverse_flow_in.avg" [dashType]="dashType">
                          <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                        </kendo-chart-series-item>
                        <kendo-chart-series-item name="Min" type="line" color="#ffd246" [style]="style"
                          [markers]="markers" [data]="data_reverse_flow_in.min" [dashType]="dashType">
                          <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                        </kendo-chart-series-item>
                        <kendo-chart-series-item name="Current" type="line" [style]="style" color="#28b4c8"
                          [markers]="markers" [data]="data_reverse_flow_in.current">
                          <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                        </kendo-chart-series-item>
                      </kendo-chart-series>
                      <kendo-chart-legend [position]="'bottom'" [visible]="true"></kendo-chart-legend>
                      <kendo-chart-tooltip [visible]="true"></kendo-chart-tooltip>
                    </kendo-chart>
                  </div>
                </div>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col-sm-12 col-md-12">
                <div class="card text-white bg-gray-800 mt-2 mr-0 ml-0 mb-0">
                  <div class="card-body">
                    <kendo-chart>
                      <kendo-chart-area background="none" [margin]="0" [height]="200">
                      </kendo-chart-area>
                      <kendo-chart-title text="Flow"></kendo-chart-title>
                      <kendo-chart-value-axis>
                        <kendo-chart-value-axis-item [min]="0" [max]="5000">
                        </kendo-chart-value-axis-item>
                      </kendo-chart-value-axis>
                      <kendo-chart-category-axis>
                        <kendo-chart-category-axis-item [categories]="data_categories" [plotBands]="categoryPlotBands"
                          [title]="{ text: '' }">
                        </kendo-chart-category-axis-item>
                      </kendo-chart-category-axis>
                      <kendo-chart-series>
                        <kendo-chart-series-item name="Max" type="line" color="#ff6358" [style]="style"
                          [markers]="markers" [data]="data_normal_flow_out.max" [dashType]="dashType">
                          <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                        </kendo-chart-series-item>
                        <kendo-chart-series-item name="Average" type="line" color="#78d237" [style]="style"
                          [markers]="markers" [data]="data_normal_flow_out.avg" [dashType]="dashType">
                          <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                        </kendo-chart-series-item>
                        <kendo-chart-series-item name="Min" type="line" color="#ffd246" [style]="style"
                          [markers]="markers" [data]="data_normal_flow_out.min" [dashType]="dashType">
                          <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                        </kendo-chart-series-item>
                        <kendo-chart-series-item name="Current" type="line" [style]="style" color="#28b4c8"
                          [markers]="markers" [data]="data_normal_flow_out.current">
                          <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                        </kendo-chart-series-item>
                      </kendo-chart-series>
                      <kendo-chart-legend [position]="'bottom'" [visible]="true"></kendo-chart-legend>
                      <kendo-chart-tooltip [visible]="true"></kendo-chart-tooltip>
                    </kendo-chart>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12">
                <div class="card text-white bg-gray-800 mt-2 mr-0 ml-0 mb-0">
                  <div class="card-body">
                    <kendo-chart>
                      <kendo-chart-area background="none" [margin]="0" [height]="200">
                      </kendo-chart-area>
                      <kendo-chart-title text="Flow"></kendo-chart-title>
                      <kendo-chart-value-axis>
                        <kendo-chart-value-axis-item [min]="0" [max]="5000">
                        </kendo-chart-value-axis-item>
                      </kendo-chart-value-axis>
                      <kendo-chart-category-axis>
                        <kendo-chart-category-axis-item [categories]="data_categories" [plotBands]="categoryPlotBands"
                          [title]="{ text: '' }">
                        </kendo-chart-category-axis-item>
                      </kendo-chart-category-axis>
                      <kendo-chart-series>
                        <kendo-chart-series-item name="Max" type="line" color="#ff6358" [style]="style"
                          [markers]="markers" [data]="data_reverse_flow_out.max" [dashType]="dashType">
                          <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                        </kendo-chart-series-item>
                        <kendo-chart-series-item name="Average" type="line" color="#78d237" [style]="style"
                          [markers]="markers" [data]="data_reverse_flow_out.avg" [dashType]="dashType">
                          <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                        </kendo-chart-series-item>
                        <kendo-chart-series-item name="Min" type="line" color="#ffd246" [style]="style"
                          [markers]="markers" [data]="data_reverse_flow_out.min" [dashType]="dashType">
                          <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                        </kendo-chart-series-item>
                        <kendo-chart-series-item name="Current" type="line" [style]="style" color="#28b4c8"
                          [markers]="markers" [data]="data_reverse_flow_out.current">
                          <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                        </kendo-chart-series-item>
                      </kendo-chart-series>
                      <kendo-chart-legend [position]="'bottom'" [visible]="true"></kendo-chart-legend>
                      <kendo-chart-tooltip [visible]="true"></kendo-chart-tooltip>
                    </kendo-chart>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row no-gutters mr-1 ml-1 pb-2">
          <div *ngFor="let item of cctv_list;index as i;" class="col-4 p-1">
            <div class="w-100">
              <img [src]="item" width="100%" class="rounded">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 col-lg-12" [ngClass]="(view == 1)?'col-xl-7':'col-xl-12 order-1'">
      <div class="p-2" style="background-color: #404040;">
        <div class="position-relative w-100">
          <div class="position-absolute w-100 text-drak d-flex justify-content-end">
            <i class="fa fa-bars m-1 pointer" (click)="changeView(2)"
              [ngClass]="(view == 2)?'text-primary':'text-dark'"></i>
            <i class="fa fa-th-large m-1 pointer" (click)="changeView(1)"
              [ngClass]="(view == 1)?'text-primary':'text-dark'"></i>
          </div>
          <img [src]="corridor_img" style="width: 100%;" [style.height.px]="view == 1?200:290">
        </div>
        <div #container class="mt-3 position-relative">
          <img [height]="road_img_height" [src]="road_img" style="width: 100%;" class="position-absolute">

          <!-- <div *ngIf="!is_loading_lms" class="w-100 d-flex position-absolute align-items-center"
            [style.height.px]="road_img_height">
            <div class="w-100 d-flex" style="margin-bottom: 8px;">
              <div *ngFor="let item of km_list;index as i;" class="w-100 text-center mt-2">
                <div class="d-flex justify-content-center align-items-center">
                  <div class="mr-1 rounded-circle bg-info d-flex justify-content-center align-items-center"
                    style="width:auto;height:auto;min-height:32px;min-width:32px">
                    <strong>{{item}}</strong></div>
                </div>
              </div>
            </div>
          </div> -->

          <!-- <div *ngFor="let item of boardsign_list;index as i;">
            <div #LMSOUTT class="position-absolute" style="width: 5px;background-color: yellow;"
              [style.height.px]="(road_img_height/2)-30" *ngIf="item?.station.direction == this.directionEnum.OUT"
              [style.left.px]="((((container.offsetWidth/this.km_list.length)/1000))*(padding_m+(item?.station?.km*1000)+item?.station?.m-(st_km*1000)))"
              [style.margin-top.px]="7">
            </div>
            <div #LMSINT class="position-absolute" style="width: 5px;background-color: yellow;"
              [style.height.px]="(road_img_height/2)-30" *ngIf="item?.station.direction == this.directionEnum.IN"
              [style.left.px]="((((container.offsetWidth/this.km_list.length)/1000))*(padding_m+(item?.station?.km*1000)+item?.station?.m-(st_km*1000)))"
              [style.margin-top.px]="268">
            </div>
          </div> -->


          <div *ngIf="is_loading_lms" class="w-100 d-flex position-absolute align-items-center"
            [style.height.px]="road_img_height">
            <div class="w-100 d-flex justify-content-center" style="margin-bottom: 8px;">
              <i class="fa fa-spinner fa-spin fa-fw fa-5x" aria-hidden="true"></i>
            </div>
          </div>

          <div>
            <div *ngFor="let item of boardsign_list;index as i;">
              <div #LMSOUT class="lms-station-out position-absolute"
                *ngIf="item?.station.direction == this.directionEnum.OUT" [style.min-width.px]="(road_img_height/2)-15"
                [style.top.px]="(road_img_height/2)-15"
                [style.left.px]="((((container.offsetWidth/this.km_list.length)/1000))*(padding_m+(item?.station?.km*1000)+item?.station?.m-(st_km*1000)))-((item?.layers?.length>1?item?.is_dir_back?sign_height*(item?.layers?.length+1.5+1/3):sign_height*(item?.layers?.length+2.2):item?.is_dir_back?sign_height*4.3:sign_height*5.3))">
                <div class="d-flex justify-content-center">
                  <div>
                    <div *ngFor="let l of item?.layers;index as ii;">
                      <div class="d-flex justify-content-end flex-wrap">
                        <div *ngFor="let sign of l;index as iii;">
                          <div *ngIf="sign?.dir == signDir.Back">
                            <div class="pointer sign-box-back ml-2 mr-2" title="{{item?.name}}"
                              (click)="clickOpenInfomodal(item)"
                              [style.width.px]="get_sign_width(sign?.ratio,sign_height)" [style.height.px]="sign_height"
                              [style.margin-bottom.px]="item?.layers?.length>1? sign_height/-1.5:0"
                              *ngIf="sign?.dir == signDir.Back">
                              <app-sign-image-item [media]="sign?.media?.media" [sign_height]="sign_height"
                                [ratio]="sign?.ratio" [is_board]="true" [config_road]="config_road" [rain]="item?.rain"
                                [resize]="(sign_height/sign?.sign_type_object?.height)"
                                *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)">
                              </app-sign-image-item>
                              <app-playlist-item [show_count]="true" [config_road]="config_road" [rain]="item?.rain"
                                [resize]="(sign_height/sign?.sign_type_object?.height)"
                                *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist"
                                [playlist]="sign?.media?.items" [height]="sign_height"
                                [width]="get_sign_width(sign?.ratio,sign_height)">
                              </app-playlist-item>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div *ngFor="let l of item?.layers;index as ii;">
                      <div class="d-flex justify-content-center flex-wrap">
                        <div *ngFor="let sign of l;index as iii;">
                          <div *ngIf="sign?.dir == signDir.Front">
                            <div class="pointer sign-box ml-2 mr-2" title="{{item?.name}}"
                              (click)="clickOpenInfomodal(item)"
                              [style.width.px]="get_sign_width(sign?.ratio,sign_height)" [style.height.px]="sign_height"
                              *ngIf="sign?.dir == signDir.Front">
                              <app-sign-image-item [media]="sign?.media?.media" [sign_height]="sign_height"
                                [ratio]="sign?.ratio" [is_board]="true" [config_road]="config_road" [rain]="item?.rain"
                                [resize]="(sign_height/sign?.sign_type_object?.height)"
                                *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)">
                              </app-sign-image-item>
                              <app-playlist-item [show_count]="true" [config_road]="config_road" [rain]="item?.rain"
                                [resize]="(sign_height/sign?.sign_type_object?.height)"
                                *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist"
                                [playlist]="sign?.media?.items" [height]="sign_height"
                                [width]="get_sign_width(sign?.ratio,sign_height)">
                              </app-playlist-item>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div #LMSIN class="lms-station-in position-absolute"
                *ngIf="item?.station.direction == this.directionEnum.IN"
                [style.left.px]="((((container.offsetWidth/this.km_list.length)/1000))*(padding_m+(item?.station?.km*1000)+item?.station?.m-(st_km*1000)))-((item?.layers?.length>1?item?.is_dir_back?sign_height*(item?.layers?.length-0.5+1/3)+(item?.layers?.length*2):sign_height*(item?.layers?.length-0.5)+(item?.layers?.length*2):item?.is_dir_back?sign_height*1.5:sign_height*0.5))"
                [style.min-width.px]="(road_img_height/2)-15" [style.top.px]="(road_img_height)-5">
                <div class="d-flex justify-content-center">
                  <div>
                    <div *ngFor="let l of item?.layers;index as ii;">
                      <div class="d-flex justify-content-end flex-wrap">
                        <div *ngFor="let sign of l;index as iii;">
                          <div *ngIf="sign?.dir == signDir.Back">
                            <div class="pointer sign-box-back ml-2 mr-2" title="{{item?.name}}"
                              (click)="clickOpenInfomodal(item)"
                              [style.width.px]="get_sign_width(sign?.ratio,sign_height)" [style.height.px]="sign_height"
                              [style.margin-bottom.px]="item?.layers?.length>1? sign_height/-1.5:0"
                              *ngIf="sign?.dir == signDir.Back">
                              <app-sign-image-item [media]="sign?.media?.media" [sign_height]="sign_height"
                                [ratio]="sign?.ratio" [is_board]="true" [config_road]="config_road" [rain]="item?.rain"
                                [resize]="(sign_height/sign?.sign_type_object?.height)"
                                *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)">
                              </app-sign-image-item>
                              <app-playlist-item [show_count]="true" [config_road]="config_road" [rain]="item?.rain"
                                [resize]="(sign_height/sign?.sign_type_object?.height)"
                                *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist"
                                [playlist]="sign?.media?.items" [height]="sign_height"
                                [width]="get_sign_width(sign?.ratio,sign_height)">
                              </app-playlist-item>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div *ngFor="let l of item?.layers;index as ii;">
                      <div class="d-flex justify-content-center flex-wrap">
                        <div *ngFor="let sign of l;index as iii;">
                          <div *ngIf="sign?.dir == signDir.Front">
                            <div class="pointer sign-box ml-2 mr-2" title="{{item?.name}}"
                              (click)="clickOpenInfomodal(item)"
                              [style.width.px]="get_sign_width(sign?.ratio,sign_height)" [style.height.px]="sign_height"
                              *ngIf="sign?.dir == signDir.Front">
                              <app-sign-image-item [media]="sign?.media?.media" [sign_height]="sign_height"
                                [ratio]="sign?.ratio" [is_board]="true" [config_road]="config_road" [rain]="item?.rain"
                                [resize]="(sign_height/sign?.sign_type_object?.height)"
                                *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)">
                              </app-sign-image-item>
                              <app-playlist-item [show_count]="true" [config_road]="config_road" [rain]="item?.rain"
                                [resize]="(sign_height/sign?.sign_type_object?.height)"
                                *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist"
                                [playlist]="sign?.media?.items" [height]="sign_height"
                                [width]="get_sign_width(sign?.ratio,sign_height)">
                              </app-playlist-item>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div [style.height.px]="road_img_height+10"></div>
        <!-- <img height="500" [src]="road_img1" style="width: 100%;"> -->
      </div>
    </div>
  </div>
</div>

<div bsModal #boardsingInfoModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" (onHide)="onHideInfoModal()" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-950">
        <div class="modal-title d-flex justify-content-center align-items-center w-100"> <i
            class="icon-grid icon mr-2 h5"></i><span class="h4">{{boardsignInfo?.name}}
          </span>
        </div>
        <button type="button" class="close text-light" (click)="clickCloseInfoModal()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-0 m-0 modal-body-h scrollbar-style" style="overflow: auto;">
        <div class="rounded modal-panel-h pt-3">
          <div class="d-flex justify-content-center">
            <div class="mr-2">
              <div class="sign-dir-text mb-1 text-dark d-flex justify-content-center"><span
                  class="small text-light">ป้ายหน้า</span></div>
              <div *ngFor="let l of boardsignInfo?.layers;index as ii;">
                <div class="d-flex justify-content-center flex-wrap">
                  <div *ngFor="let sign of l;index as iii;">
                    <div *ngIf="sign?.dir == signDir.Front">
                      <div class="sign-box" [style.width.px]="get_sign_width(sign?.ratio,set_sign_height_modal)"
                        [style.height.px]="set_sign_height_modal" *ngIf="sign?.dir == signDir.Front">
                        <app-sign-image-item [media]="sign?.media?.media" [sign_height]="set_sign_height_modal"
                          [ratio]="sign?.ratio" [is_board]="true" [config_road]="config_road"
                          [rain]="boardsignInfo?.rain" [resize]="(set_sign_height_modal/sign?.sign_type_object?.height)"
                          *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)">
                        </app-sign-image-item>
                        <app-playlist-item [show_count]="true" [config_road]="config_road" [rain]="boardsignInfo?.rain"
                          [resize]="(set_sign_height_modal/sign?.sign_type_object?.height)"
                          *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist"
                          [playlist]="sign?.media?.items" [height]="set_sign_height_modal"
                          [width]="get_sign_width(sign?.ratio,set_sign_height_modal)">
                        </app-playlist-item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="ml-2">
              <div class="sign-dir-text mb-1 text-dark d-flex justify-content-center"><span
                  class="small text-light">ป้ายหลัง</span></div>
              <div *ngFor="let l of boardsignInfo?.layers;index as ii;" [style.height.px]="set_sign_height_modal+4">
                <div class="d-flex justify-content-center flex-wrap">
                  <div *ngFor="let sign of l;index as iii;">
                    <div *ngIf="sign?.dir == signDir.Back">
                      <div class="sign-box" [style.width.px]="get_sign_width(sign?.ratio,set_sign_height_modal)"
                        [style.height.px]="set_sign_height_modal" *ngIf="sign?.dir == signDir.Back">
                        <app-sign-image-item [media]="sign?.media?.media" [sign_height]="set_sign_height_modal"
                          [ratio]="sign?.ratio" [is_board]="true" [config_road]="config_road"
                          [rain]="boardsignInfo?.rain" [resize]="(set_sign_height_modal/sign?.sign_type_object?.height)"
                          *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)">
                        </app-sign-image-item>
                        <app-playlist-item [show_count]="true" [config_road]="config_road" [rain]="boardsignInfo?.rain"
                          [resize]="(set_sign_height_modal/sign?.sign_type_object?.height)"
                          *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist"
                          [playlist]="sign?.media?.items" [height]="set_sign_height_modal"
                          [width]="get_sign_width(sign?.ratio,set_sign_height_modal)">
                        </app-playlist-item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-900 w-100 text-center mt-3 mb-1 d-flex justify-content-center align-items-center"
            style="height: 45px">
            ข้อมูลอุปกรณ์
          </div>
          <div *ngFor="let de of devices;index as i;">
            <div class="d-flex justify-content-center mt-2">
              <!-- <div class="bg-gray-900 pl-3 pr-3 pt-1 pb-1 rounded">
                  {{de?.view}}
                </div> -->
              <div class="bg-gray-900 pl-3 pr-3 pt-1 pb-1 rounded">
                {{de?.name}}
              </div>
            </div>
            <div class="d-flex justify-content-center align-items-center mt-2">
              <i *ngIf="(de?.link)" (click)="clickCameraImg(de,lms_item?.station)" title="+"
                class="fa fa-image pointer text-info mr-2" style="font-size: 20px"></i>
              <i *ngIf="de?.rtsp_link" (click)="clickCameraRtsp(de,lms_item?.station)" title="Stream Rtsp"
                class="fa fa-play-circle-o fa-2x pointer text-info"></i>
              <i *ngIf="de?.cctv_flv" (click)="clickCameraFlv(de,lms_item?.station)" title="Stream FLV"
                class="fa fa-play-circle-o fa-2x pointer text-info"></i>
            </div>
            <div class="p-2 d-flex justify-content-center">
              <img *ngIf="de?.link" [src]="de?.link|linktime" width="100%" height="auto" style="max-width: 600px" />
            </div>
          </div>
        </div>

      </div>
      <div class="modal-footer bg-gray-950 panel-layer-footer d-flex justify-content-end align-items-center">
        <div>
          <button type="button" class="btn btn-danger mr-1 text-white" (click)="clickCloseInfoModal()">
            ปิด
          </button>
        </div>
        <div>
          <button type="button" class="btn btn-primary text-white ml-1"
            (click)="openChangeMediaModal(boardsignInfo?.id)"><i class="fa fa-eye"></i>
            เปลี่ยนป้าย</button>
        </div>
      </div>
    </div>
  </div>
</div>

<app-change-media-panel [clickOpen]="clickOpenChangeMedia" [board_select_list]="board_select_list"
  (onClose)="closeChangeMediaModal($event)"></app-change-media-panel>

<div *ngIf="is_toggle_camera_rtsp">
  <app-cctv-display-rtsp [item]="this.camera_rtsp_item" [station]="boardsignInfo?.station"
    [toggle]="is_toggle_camera_rtsp" (onClose)="closeCameraRtsp($event)"></app-cctv-display-rtsp>
</div>

<div *ngIf="is_toggle_camera_flv">
  <app-cctv-display-flv [item]="this.camera_flv_item" [station]="boardsignInfo?.station" [toggle]="is_toggle_camera_flv"
    (onClose)="closeCameraFlv($event)"></app-cctv-display-flv>
</div>

<div *ngIf="is_toggle_camera_img">
  <app-cctv-display-img [item]="this.camera_img_item" [station]="boardsignInfo?.station" [toggle]="is_toggle_camera_img"
    (onClose)="closeCameraImg($event)"></app-cctv-display-img>
</div>
