import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class IncidentService {

  constructor(
    private api : ApiService,
  ) { }
  
  async addIncident(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.addIncident(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getIncidentById(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getIncidentById(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getIncident(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getIncident(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getIncidentName(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getIncidentName(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getIncidentType(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getIncidentType(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(res.data.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async addIncidentType(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.addIncidentType(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async rmIncident(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.rmIncident(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async rmIncidentType(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.rmIncidentType(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async uploadImages(image: File, bucket_name: string, path: string, image_name?: string){
    return new Promise((reslove,reject)=>{
      this.api.uploadImages(image,bucket_name,path,image_name).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async rmImages(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.rmImages(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async addIncidentAct(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.addIncidentAct(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
}
