import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash'
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FuncService } from 'src/app/services/user/func.service';
import { IncidentService } from 'src/app/services/user/incident.service';
import { IncidentModalComponent } from './incident-modal/incident-modal.component';
import { DeviceService } from 'src/app/services/user/device.service';
import { CommonService } from 'src/app/services/common.service';
import { permission } from 'src/app/views/incident/menu';
import * as mm from 'moment'
@Component({
  selector: 'app-incident',
  templateUrl: './incident.component.html',
  styleUrls: ['./incident.component.scss']
})
export class IncidentComponent implements OnInit {
  modalRef: BsModalRef;
  allowCustom: boolean = false;
  allData : any = []
  route_list : any
  search_txt : any
  search_route : any
  direction : any = [true,true]
  st_date : any
  en_date : any
  data_list : any = []
  allType : any
  list: any;
  total : number = 0;
  currentPage : number = 1;
  itemsPerPage : number = 15;
  data_route: any;
  route: any;
  st_km: any;
  en_km: any;
  src_type: any = null

  isAdd: boolean = false;
  isDetail: boolean = false;
  isDelete: boolean = false;
  constructor(
    private func: FuncService,
    private modalService: BsModalService,
    private inc_S: IncidentService,
    private devS: DeviceService,
    private commonService: CommonService
  ) { }

  async ngOnInit() {
    this.isAdd = await this.commonService.getPermission(permission.incident.add_id).toPromise();
    this.isDetail = await this.commonService.getPermission(permission.incident.detail_id).toPromise();
    this.isDelete = await this.commonService.getPermission(permission.incident.delete_id).toPromise();
    this.getData()
  }
  FilterRoute(evt:any){
    if(evt.length>0){
      this.route_list = this.route_list.filter(res => res.includes(evt))
    }else if(evt.length==0){
      this.route_list = this.route
    }else{

    }
  }
  async ChageRoute(evt) {
    console.log(evt)
  }

  async search(){
    this.data_list = null
    let all : any = {}
    if(this.search_txt) all.incident_type = this.search_txt.id
    if(this.search_route) all.route = this.search_route
    if(this.src_type) all.src_type = this.src_type
    if(this.st_km) all.st_km = this.st_km
    if(this.en_km) all.en_km = this.en_km
    if(this.st_date) all.st_date = mm(this.st_date).add(7, "hours").toISOString()
    if(this.en_date) all.en_date = mm(this.en_date).endOf("day").add(7, "hours").toISOString()
    if(this.direction.length){
      let dir = []
      for (const i in this.direction) {
        if(this.direction[i]) {
          dir.push(Number(i)+1)
        }
      }
      if(dir.toString().match('1') && dir.toString().match('2')) dir.push(3)
      all.direction = dir.toString()
    }
    this.data_list = await this.inc_S.getIncident(all)
  }
  async rmlist(id) {
    let result = await this.func.alertpopup('<h4>คุณต้องการลบใช่หรือไม่</h4>', `<i class="icon-info mr-2"></i>ลบผู้ใช้งาน`, 1);
    if (result) {
      let res: any = await this.inc_S.rmIncident(id)
      if (res) this.getData()
    }
  }
  async getData(){
    this.allData = await this.inc_S.getIncidentType()
    this.allType = this.allData 
    this.data_list = await this.inc_S.getIncident()
    this.route_list = await this.devS.getRoute()
    this.route = this.route_list
  }
  openModal(data?: any) {
    const initialState = {
      values: data,
      list: this.list,
      inc_type : this.allType
    };
    this.modalRef = this.modalService.show(IncidentModalComponent, { initialState, class: 'w-80',backdrop: 'static'});
    this.modalRef.content.event.subscribe(data => {
      if (data) this.getData()
      // return resolve(data);
    });
  }
  pageChanged(event: any): void {
    if (this.currentPage != event.page) {
      this.currentPage = event.page;
      // this.getDevice();
    }
  }
  handleFilter(value) {
    if (value.length > 0) {
     this.allData = this.allType.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.allData = _.cloneDeep(this.allType);
    } else {
     this.allData = [];
    }
  }
  handleValue(value) {
    if (value) {
      this.search_txt = value;
    } else {
      this.search_txt = null;
    }
  }
}
