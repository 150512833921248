<div class="modal-header">
    <h4 class="modal-title pull-left">ประเภทเหตุการณ์</h4>
    <button type="button" class="close pull-right text-white" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body text-center">
    <div class="row">
        <div class="col-12">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text">ชื่อประเภทเหตุการณ์ <span class="red-txt"> *</span></p>
                </div>
                <input type="text" class="form-control" [(ngModel)]="name">
            </div>
        </div>
        <div class="col-12">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text">ชื่อประเภทเหตุการณ์ (EN)</p>
                </div>
                <input type="text" class="form-control" [(ngModel)]="name_en">
            </div>
        </div>
        <div class="col-12">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text">รูปแบบไฟล์สัญลักษณ์<span class="red-txt"> *</span></p>
                </div>
                <select class="form-control" [(ngModel)]="type">
                    <option value=null>ระบุรูปแบบไฟล์ของรูปสัญลักษณ์</option>
                    <option value=1>Image (URL)</option>
                    <option value=2>Image (Base64)</option>
                    <option value=3>Font Awesome</option>
                </select>
            </div>
        </div>
        <div class="col-12">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text">สัญลักษณ์<span class="red-txt"> *</span></p>
                </div>
                <input *ngIf="type < 3" type="text" [(ngModel)]="symbol" class="form-control">
                <select *ngIf="type==3" class="form-control" name="icon_color" [(ngModel)]="symbol">
                    <option value="fa fa-ban">fa fa-ban</option>
                    <option value="fa fa-car">fa fa-car</option>
                    <option value="fa fa-bicycle">fa fa-bicycle</option>
                    <option value="fa fa-bus">fa fa-bus</option>
                    <option value="fa fa-times">fa fa-times</option>
                    <option value="fa fa-id-card">fa fa-id-card</option>
                    <option value="fa fa-exclamation-circle">fa fa-exclamation-circle</option>
                    <option value="fa fa-exclamation-triangle">fa fa-exclamation-triangle</option>
                    <option value="fa fa-fire">fa fa-fire</option>
                    <option value="fa fa-users">fa fa-users</option>
                    <option value="fa fa-minus-circle">fa fa-minus-circle</option>
                    <option value="fa fa-motorcycle">fa fa-motorcycle</option>
                    <option value="fa fa-plus-circle">fa fa-plus-circle</option>
                    <option value="fa fa-question-circle">fa fa-question-circle</option>
                    <option value="fa fa-road">fa fa-road</option>
                    <option value="fa fa-truck">fa fa-truck</option>
                    <option value="fa fa-user-times">fa fa-user-times</option>
                    <option value="fa fa-user">fa fa-user</option>
                    <option value="fa fa-wheelchair">fa fa-wheelchair</option>
                    <option value="fa fa-taxi">fa fa-taxi</option>
                    <option value="fa fa-ambulance">fa fa-ambulance</option>
                    <option value="fa fa-train">fa fa-train</option>
                    <option value="fa fa-arrow-down">fa fa-arrow-down</option>
                    <option value="fa fa-arrow-left">fa fa-arrow-left</option>
                    <option value="fa fa-arrow-right">fa fa-arrow-right</option>
                    <option value="fa fa-arrow-up">fa fa-arrow-up</option>
                    <option value="fa fa-hospital-o">fa fa-hospital-o</option>
                    <option value="fa fa-close">fa fa-close</option>
                    <option value="fa fa-heart">fa fa-heart</option>
                    <option value="fa fa-check">fa fa-check</option>
                    <option value="fa fa-ellipsis-h">fa fa-ellipsis-h</option>
                    <option value="fa fa-umbrella">fa fa-umbrella</option>
                    <option value="fa fa-bolt">fa fa-bolt</option>
                    <option value="fa fa-cloud">fa fa-cloud</option>
                    <option value="fa fa-random">fa fa-random</option>
                    <option value="fa fa-tint">fa fa-tint</option>
                    <option value="fa fa-flag">fa fa-flag</option>
                    <option value="fa fa-map-marker">fa fa-map-marker</option>
                    <option value="fa fa-cogs">fa fa-cogs</option>
                    <option value="fa fa-lightbulb-o">fa fa-lightbulb-o</option>
                    <option value="fa fa-moon-o">fa fa-moon-o</option>
                    <option value="fa fa-wheelchair">fa fa-wheelchair</option>
                    <option value="fa fa-tachometer">fa fa-tachometer</option>
                    <option value="icon-speedometer">icon-speedometer</option>
                    <option value="icon-drop">icon-drop</option>
                    <option value="icon-wrench">icon-wrench</option>
                    <option value="icon-umbrella">icon-umbrella</option>
                    <option value="icon-support">icon-support</option>
                    <option value="icomoon-car-collision">icomoon-car-collision</option>
                    <option value="icomoon-engine-problems">icomoon-engine-problems</option>
                    <option value="icomoon-road-repair">icomoon-road-repair</option>
                </select>
            </div>
        </div>

        <div class="col-12">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text">สี</p>
                </div>
                <input type="color" class="form-control" [(ngModel)]="icon_color">
                
            </div>
        </div>
        <div class="col-12">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text">ตัวอย่าง</p>
                </div>
                <img *ngIf="type < 3" [src]="symbol" alt="" height="50">
                <i *ngIf="type==3" class="{{symbol||'icon-close'}}" [ngStyle]="{'color': icon_color}"></i>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="close()">ปิด</button>
    <button type="button" class="btn btn-info" [disabled]="(!type || !name || !symbol)? 'disabled': null"
        (click)="save()">ยืนยัน</button>
</div>