export const incident_url = "incident";
export const id = 100;
// Mockup id,Nodata in Doc
export const menuItems = [
    {
        id: 101,
        name: 'Incident list ',
        url: `/${incident_url}/list`,
        icon: 'fa fa-sun-o',
        parent_id: id
    },
    {
        id: 120,
        name: 'ประเภทเหตุการณ์',
        url: `/${incident_url}/type`,
        icon: 'fa fa-sun-o',
        parent_id: id
    },

];
export const permission = {
    incident: {
        add_id: 102,
        detail_id: 103,
        detail: {
            edit_detail_id: 104,
            edit_detail: {
                add_data_bank_id: 105,
                add_news_id: 106,
                manage_inc_id: 107
            },
            activity_log_id: 108,
            activity_log: {
                manage_inc_id: 109
            },
            news_id: 111,
            news: {
                add_id: 112,
                info_id: 113,
                edit_id: 114,
                manage_id: 115,
            },
            sign_control_id: 116,
            sign_control: {
                calendar_id: 117,
                change_media_id: 118
            }
        },
        delete_id: 119,
    },
    incident_type: {
        add_id: 121,
        edit_id: 122,
        suggestion_id: 123,
    },
};