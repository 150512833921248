import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash'
import { IncidentService } from 'src/app/services/user/incident.service';
@Component({
  selector: 'app-incident-type-modal',
  templateUrl: './incident-type-modal.component.html',
  styleUrls: ['./incident-type-modal.component.scss']
})
export class IncidentTypeModalComponent implements OnInit {

  public event: EventEmitter<any> = new EventEmitter();
  values : any;
  list : any;
  changed:boolean=false
  type : any = null
  symbol : any
  name : any
  name_en : any
  icon_color : any
  constructor(
    private inc_S: IncidentService,
    private bsModalRef: BsModalRef,
  ) { }

  ngOnInit(): void {
    console.log(this.values)
    if(this.values){
      this.name = this.values.name || null
      this.name_en = this.values.name_en || null
      this.type = Number(this.values.icon_type) || null
      this.icon_color = this.values.icon_color || null
      this.symbol = this.values.icon || null
    }
  }
  async save(){
    let all : any = {
      name : this.name || null,
      name_en : this.name_en || null,
      icon_type : this.type || null,
      icon_color : this.icon_color || null,
      icon : this.symbol || null,
      status: 1
    }
    if(this.values && this.values.id) all.id = this.values.id 
    let res : any = await this.inc_S.addIncidentType(all)
    console.log(res)
    if(res) this.confirm()
  }
  close(chk?:any) {
    if (chk && !_.isEmpty(this.values)) {
      this.confirm()
    } else {
      this.bsModalRef.hide();
    }
  }

  confirm() {
    this.event.emit(true);
    this.bsModalRef.hide();
  }
  cancel() {
    if(this.changed) this.confirm
    this.event.emit(false);
    this.bsModalRef.hide();
  }
}
