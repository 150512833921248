import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { IncidentComponent } from './incident/incident.component';
import { IncidentTypeComponent } from './incident-type/incident-type.component';

export const routes: Routes = [{
  path: '',
  redirectTo: 'incident',
  pathMatch: 'full',
},
{
  path: '',
  component: HomeComponent,
  children: [
    {
      path: 'list',
      component: IncidentComponent,
    },
    {
      path: 'type',
      component: IncidentTypeComponent,
    },
    
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IncidentRoutingModule { }
