// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  from_web: 1,
  timeInterval: 60000, // ms
  api: {
    domain_assets: "http://dev.api.lms-m7.transcode.co.th/api/asset-service",
    domain_auth: "http://dev.api.lms-m7.transcode.co.th/api/auth-service",
    domain_sign_control: "http://dev.api.lms-m7.transcode.co.th/api/signctrl-service",
    domain_traffic_tool: "http://dev.api.lms-m7.transcode.co.th/api/traffic-tool-service",
    domain_incident: "http://dev.api.lms-m7.transcode.co.th/api/incident-service",
    domain_information: "http://dev.api.lms-m7.transcode.co.th/api/information-service",
  },
  firebase: {
    apiKey: "AIzaSyDAglrUPPC39MCxtyWjdz5QecalfqPHjvM",
    authDomain: "shw-tc.firebaseapp.com",
    databaseURL: "https://shw-tc.firebaseio.com",
    projectId: "shw-tc",
    storageBucket: "shw-tc.appspot.com",
    messagingSenderId: "886032913437",
    appId: "1:886032913437:web:6f09c1560d06d385b622f4",
    measurementId: "G-NJV6MZ2XWX"
  },
  mapbox: {
    accessToken: 'pk.eyJ1IjoibWF5c2FpIiwiYSI6ImNrZmdodDFhbDAydDEyd3E5ZGl1d3RkMTYifQ.yRq45sGr7-cQ73hIxX8P3Q',
    style: 'mapbox://styles/mapbox/streets-v11'
  },
  sentry: {
    dsn: 'https://c78b422b751547b3b30225a041dc228f@o291271.ingest.sentry.io/1532260'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
