import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { setTheme } from 'ngx-bootstrap/utils';
import { MessagingService } from './services/messaging/messaging.service';
import { routes as routes_user } from 'src/app/views/user-management/user-management-routing.module';
import { id as user_id, user_url } from 'src/app/views/user-management/menu';
import { routes as routes_traffictool } from 'src/app/views/traffic-tool/traffic-tool-routing.module';
import { id as traffic_id, traffic_url } from 'src/app/views/traffic-tool/menu';
import { routes as routes_incident } from 'src/app/views/incident/incident-routing.module';
import { id as incident_id, incident_url } from 'src/app/views/incident/menu';
import { id as assest_id, mainRouteUrl as assest_url } from 'src/app/views/assets-management/_menu';
import { routes as routes_assets } from 'src/app/views/assets-management/assets-management-routing.module';
import { id as sign_id, mainRouteUrl as sign_url } from 'src/app/views/sign-control/_menu';
import { routes as routes_sign } from 'src/app/views/sign-control/sign-control-routing.module';
import { routes as routes_information } from 'src/app/views/information/information-routing.module';
import { id as information_id, information_url } from 'src/app/views/information/_menu';
import { routes as routes_corridor } from 'src/app/views/corridor-control/corridor-control-routing.module';
import { id as corridor_id, mainRouteUrl as corridor_url } from 'src/app/views/corridor-control/_menu';
import { CommonService } from 'src/app/services/common.service';
import { routes } from 'src/app/app-routing.module';
import { Subscription } from 'rxjs';
@Component({
  selector: 'body',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private mesS: MessagingService,
    private commonService: CommonService
  ) { 
    this.sub_toggle = this.commonService.getToggle().subscribe(res => {
      if(res){
        this.updateRedirect();
      }
    });
  }
  message: any;
  sub_toggle: Subscription;
  async ngOnInit() {

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      this.updateRedirect();
      window.scrollTo(0, 0);
    });

    setTheme('bs4');
    //this.mesS.requestPermission();
  }

  updateRedirect(){
    this.commonService.getNavRedirect().subscribe(res => {
      if (res) {
        routes[0].redirectTo = res;
      }
    }, error => { });
    this.commonService.getRedirect(sign_id, sign_url).subscribe(res => {
      if (res) {
        routes_sign[0].redirectTo = res;
      }
    }, error => { });
    this.commonService.getRedirect(user_id, user_url).subscribe(res => {
      if (res) {
        routes_user[0].redirectTo = res;
      }
    }, error => { });
    this.commonService.getRedirect(assest_id, assest_url).subscribe(res => {
      if (res) {
        routes_assets[0].redirectTo = res;
      }
    }, error => { });
    this.commonService.getRedirect(traffic_id, traffic_url).subscribe(res => {
      if (res) {
        routes_traffictool[0].redirectTo = res;
      }
    }, error => { });
    this.commonService.getRedirect(incident_id, incident_url).subscribe(res => {
      if (res) {
        routes_incident[0].redirectTo = res;
      }
    }, error => { });
    this.commonService.getRedirect(corridor_id, corridor_url).subscribe(res => {
      if (res) {
        routes_corridor[0].redirectTo = res;
      }
    }, error => { });
    // this.commonService.getRedirect(information_id, information_url).subscribe(res => {
    //   if (res) {
    //     routes_information[0].redirectTo = res;
    //   }
    // }, error => { });
    // console.log(routes[0].redirectTo);
    // console.log(routes_user[0].redirectTo);
  }
}
