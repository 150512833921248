import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { StationService } from '../../../services/assets-management/station.service';
import { Station, ReqAddStation } from '../../../models/assets-management/station';
import { StatusEnum, RouteDirectionEnum, StationTypeEnum } from '../../../models/common-enum';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import * as _ from 'lodash';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Owner } from 'src/app/models/assets-management/owner';
import { Vendor } from 'src/app/models/assets-management/vendor';
import { DeviceGroup } from 'src/app/models/assets-management/device-group';
import { OwnerService } from '../../../services/assets-management/owner.service';
import { VendorService } from '../../../services/assets-management/vendor.service';
import { DeviceGroupService } from '../../../services/assets-management/device-group.service';
import { DeviceTypeService } from '../../../services/assets-management/device-type.service';
import { DeviceType } from 'src/app/models/assets-management/device-type';
import { Province, District } from 'src/app/models/common';
import * as mapboxgl from 'mapbox-gl';
import { environment } from 'src/environments/environment';
import { AttributeMarker } from '@angular/compiler/src/core';
import { permission }  from 'src/app/views/assets-management/_menu';
@Component({
  selector: 'app-station',
  templateUrl: './station.component.html',
  styleUrls: ['./station.component.scss']
})
export class StationComponent implements OnInit {
  @ViewChild('mapModal', { static: false }) public mapModal: ModalDirective;
  @ViewChild('addModal', { static: false }) public addModal: ModalDirective;
  @ViewChild('deleteModal', { static: false }) public deleteModal: ModalDirective;
  @ViewChild('swalSuccess', { static: false }) private swalSuccess: SwalComponent;
  @ViewChild('swalError', { static: false }) private swalError: SwalComponent;
  map: mapboxgl.Map;
  map_station: mapboxgl.Map;
  marker: mapboxgl.Marker;
  map_lat: number;
  map_lng: number;
  viewType = 1; //1:list,2:map
  statusEnum = StatusEnum;
  routeDirectionEnum = RouteDirectionEnum;
  stationTypeEnum = StationTypeEnum;
  myDate = new Date();
  currentPage: any = 1;
  itemsPerPage: any = 10;
  totalItems: any = 0;
  data_list: Station[] = [];
  status_list = [
    { id: StatusEnum.Active, name: "Active" },
    { id: StatusEnum.Inactive, name: "Inactive" }
  ];
  dir_list = [
    { id: RouteDirectionEnum.IN, name: "ขาเข้า" },
    { id: RouteDirectionEnum.OUT, name: "ขาออก" },
    { id: RouteDirectionEnum.INOUT, name: "ขาเข้า-ขาออก" }
  ];
  station_type_list = [
    { id: StationTypeEnum.Static, name: "Static" },
    { id: StationTypeEnum.Mobility, name: "Mobility" }
  ];
  search_status: any;
  selectItem: any = {
    name: null,
    km: null,
    m: null,
    lat: null,
    lng: null,
    license_plate_number: null,
    chassis_no: null,
    mdvr_no: null
  };

  public allowCustom: boolean = false;
  res_message: any;

  search_km_st: any;
  search_km_en: any;
  search_m_st: any;
  search_m_en: any;
  search_station: any;
  data_station_search: Station[] = [];
  station_search_list: Station[] = [];
  search_owner: any;
  data_owner: Owner[] = [];
  data_owner_modal: Owner[] = [];
  owner_list: Owner[] = [];
  search_vendor: any;
  data_vendor: Vendor[] = [];
  vendor_list: Vendor[] = [];
  search_device_group: any;
  data_device_group: DeviceGroup[] = [];
  device_group_list: DeviceGroup[] = [];
  search_device_type: any;
  data_device_type: DeviceType[] = [];
  device_type_list: DeviceType[] = [];

  station_type_modal: any;
  status_modal: any;
  dir_modal: any;
  route_modal: any;
  prov_modal: Province;
  dist_modal: District;
  owner_modal: Owner;
  lic_prov_modal: Province;

  is_loading: boolean = false;
  search_route: string;
  public data_route: Array<string> = [];
  public data_route_modal: Array<string> = [];
  route_list: string[] = [];

  data_prov_modal: Province[] = [];
  data_lic_prov_modal: Province[] = [];
  prov_list: Province[] = [];

  data_dist_modal: District[] = [];
  dist_list: District[] = [];

  isAdd: boolean = false;
  isEdit: boolean = false;

  constructor(private commonService: CommonService, private stationService: StationService, private ownerService: OwnerService,
    private vendorService: VendorService, private deviceGroupService: DeviceGroupService, private deviceTypeService: DeviceTypeService) {
      mapboxgl.accessToken = environment.mapbox.accessToken;
     }

    async ngOnInit(){
    this.is_loading = true;
    this.getRoute();
    this.getStation();
    this.getStationSearch();
    this.getOwner();
    this.getVendor();
    this.getDeviceGroup();
    this.getDeviceType();
    this.getProvince();
    this.initMapModal();
    this.initStationMap();
    this.isAdd = await this.commonService.getPermission(permission.station.add_id).toPromise();
    this.isEdit = await this.commonService.getPermission(permission.station.edit_id).toPromise();
  }
  pageChanged(event: any): void {
    console.log(event);
    
    if (this.currentPage != event.page) {
      this.currentPage = event.page;
      this.getStation();
    }
  }
  search() {
    if(this.viewType == 1){
      this.currentPage = 1;
      this.getStation();
    }else{
      this.getStationMap();
      setTimeout(() => { this.updateMap();this.map_station.resize(); this.is_loading = false; }, 1000);
    }
  }
  changeView(id) {
    if (id != this.viewType) {
      if (id == 1) {
        this.getStation();
      } else {
        this.getStationMap();
        setTimeout(() => { this.updateMap();this.map_station.resize(); this.is_loading = false; }, 1000);
        
      }
    }
  }
  handleValueStatus(value) {
    if (value) {
      //console.log(value);
      this.search_status = value;
    } else {
      this.search_status = null;
    }
  }

  getRoute() {
    this.commonService.getRoute().subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.route_list = _.cloneDeep(res.data);
              this.data_route = _.cloneDeep(res.data);
              this.data_route_modal = _.cloneDeep(res.data);
            }
          }
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
      });
  }
  getProvince() {
    this.commonService.getProvince().subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.prov_list = _.cloneDeep(res.data);
              this.data_prov_modal = _.cloneDeep(res.data);
              this.data_lic_prov_modal = _.cloneDeep(res.data);
            }
          }
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
      });
  }

  getDistrict(prov_id?: number) {
    this.commonService.getDistrict(prov_id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.dist_list = _.cloneDeep(res.data);
              this.data_dist_modal = _.cloneDeep(res.data);
            }
          }
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
      });
  }

  handleFilterRoute(value) {
    if (value.length > 0) {
      this.data_route = this.route_list.filter((s) => s.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_route = _.cloneDeep(this.route_list);
    } else {
      this.data_route = [];
    }
  }
  handleValueRoute(value) {
    if (value) {
      //console.log(value);
      this.search_route = value;
    } else {
      this.search_route = null;
    }
  }

  getStationSearch() {
    this.stationService.getStation(null, null, 1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.station_search_list = _.cloneDeep(res.data.data);
              this.data_station_search = _.cloneDeep(res.data.data);
            }
          }
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
      });
  }
  handleFilterStation(value) {
    if (value.length > 0) {
      this.data_station_search = this.station_search_list.filter(x=>x.name != null).filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_station_search = _.cloneDeep(this.station_search_list);
    } else {
      this.data_station_search = [];
    }
  }
  handleValueStation(value) {
    if (value) {
      this.search_station = value;
    } else {
      this.search_station = null;
    }
  }

  getOwner() {
    this.ownerService.getOwner(null, null, 1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.owner_list = _.cloneDeep(res.data.data);
              this.data_owner = _.cloneDeep(res.data.data);
              this.data_owner_modal = _.cloneDeep(res.data.data);
            }
          }
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
      });
  }
  handleFilterOwner(value) {
    if (value.length > 0) {
      this.data_owner = this.owner_list.filter((s) => s.company_name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_owner = _.cloneDeep(this.owner_list);
    } else {
      this.data_owner = [];
    }
  }
  handleValueOwner(value) {
    if (value) {
      this.search_owner = value;
    } else {
      this.search_owner = null;
    }
  }

  getVendor() {
    this.vendorService.getVendor(null, null, 1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.vendor_list = _.cloneDeep(res.data);
              this.data_vendor = _.cloneDeep(res.data);

            }
          }
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
      });
  }
  handleFilterVendor(value) {
    if (value.length > 0) {
      this.data_vendor = this.vendor_list.filter((s) => s.company_name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_vendor = _.cloneDeep(this.vendor_list);
    } else {
      this.data_vendor = [];
    }
  }
  handleValueVendor(value) {
    if (value) {
      this.search_vendor = value;
    } else {
      this.search_vendor = null;
    }
  }

  getDeviceGroup() {
    this.deviceGroupService.getDeviceGroup(null, null, null, null, 1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.device_group_list = _.cloneDeep(res.data.data);
              this.data_device_group = _.cloneDeep(res.data.data);
            }
          }
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
      });
  }
  handleFilterDeviceGroup(value) {
    if (value.length > 0) {
      this.data_device_group = this.device_group_list.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_device_group = _.cloneDeep(this.device_group_list);
    } else {
      this.data_device_group = [];
    }
  }
  handleValueDeviceGroup(value) {
    if (value) {
      this.search_device_group = value;
    } else {
      this.search_device_group = null;
    }
  }

  getDeviceType() {
    this.deviceTypeService.getDeviceType(null, null, null, null, 1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.device_type_list = _.cloneDeep(res.data);
              this.data_device_type = _.cloneDeep(res.data);
            }
          }
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
      });
  }
  handleFilterDeviceType(value) {
    if (value.length > 0) {
      this.data_device_type = this.device_type_list.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_device_type = _.cloneDeep(this.device_type_list);
    } else {
      this.data_device_type = [];
    }
  }
  handleValueDeviceType(value) {
    if (value) {
      this.search_device_type = value;
    } else {
      this.search_device_type = null;
    }
  }

  getStation() {
    this.data_list = [];
    let status = null;
    let route = null;
    let station = null;
    let owner = null;
    let vendor = null;
    let device_type = null;
    let device_group = null;
    if (this.search_status)
      status = this.search_status.id;
    if (this.search_route)
      route = this.search_route;
    if (this.search_station)
      station = this.search_station.id;
    if (this.search_owner)
      owner = this.search_owner.id;
    if (this.search_vendor)
      vendor = this.search_vendor.vendor_id;
    if (this.search_device_group)
      device_group = this.search_device_group.id;
    if (this.search_device_type)
      device_type = this.search_device_type.id;
    this.stationService.getStation(this.currentPage, this.itemsPerPage, null, route, this.search_km_st, this.search_km_en, this.search_m_st, this.search_m_en, status, station, owner, vendor, device_group, device_type).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.data_list = res.data.data;
              this.totalItems = res.data.total_items;
              
            }else{
              this.totalItems = 0;
            }
          }else{
            this.totalItems = 0;
          }
        }else{
          this.totalItems = 0;
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
        this.totalItems = 0;
      });
  }
  openAddModal() {
    this.selectItem = new Station();
    this.selectItem.name = null;
    this.selectItem.km = null;
    this.selectItem.m = null;
    this.selectItem.lat = null;
    this.selectItem.lng = null;
    this.status_modal = this.status_list.find(x => x.id == StatusEnum.Active);
    this.station_type_modal = this.station_type_list.find(x => x.id == StationTypeEnum.Static);
    this.route_modal = null;
    this.prov_modal = null;
    this.dist_modal = null;
    this.dir_modal = null;
    this.owner_modal = null;

    this.lic_prov_modal = null;
    this.selectItem.license_plate_number = null;
    this.selectItem.chassis_no = null;
    this.selectItem.mdvr_no = null;
    this.addModal.show();
  }
  openEditModal(item?: Station) {
    this.selectItem = item;
    this.status_modal = this.status_list.find(x => x.id == item.status);
    this.station_type_modal = this.station_type_list.find(x => x.id == item.station_type);
    if (item.route)
      this.route_modal = this.route_list.find(x => x == item.route);
    if (item.prov_id){
      this.prov_modal = this.prov_list.find(x => x.province_id == item.prov_id);
      this.getDistrict(item.prov_id);
    }
    if (item.dist_id)
      this.dist_modal = this.dist_list.find(x => x.dist_id == item.dist_id);
    if (item.dir)
      this.dir_modal = this.dir_list.find(x => x.id == item.dir);
    if (item.owner_id)
      this.owner_modal = this.owner_list.find(x => x.id == item.owner_id);

    if (item.licenes_plate_province)
      this.lic_prov_modal = this.prov_list.find(x => x.province_id == item.licenes_plate_province);
    this.addModal.show();
  }
  openDeleteModal(item?: Station) {
    this.selectItem = item;
    this.status_modal = this.status_list.find(x => x.id == item.status);
    this.deleteModal.show();
  }
  clearData() {
    this.selectItem = new Station();
  }
  handleValueStatusModal(value) {
    if (value) {
      //console.log(value);
      this.status_modal = value;
    } else {
      this.status_modal = null;
    }
  }

  handleValueDirModal(value) {
    if (value) {
      this.dir_modal = value;
    } else {
      this.dir_modal = null;
    }
  }

  handleValueStationTypeModal(value) {
    if (value) {
      this.station_type_modal = value;
    } else {
      this.station_type_modal = null;
    }
  }
  handleFilterRouteModal(value) {
    if (value.length > 0) {
      this.data_route_modal = this.route_list.filter((s) => s.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_route_modal = _.cloneDeep(this.route_list);
    } else {
      this.data_route_modal = [];
    }
  }
  handleValueRouteModal(value) {
    if (value) {
      this.route_modal = value;
    } else {
      this.route_modal = null;
    }
  }

  handleFilterProvModal(value) {
    if (value.length > 0) {
      this.data_prov_modal = this.prov_list.filter((s) => s.province_name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_prov_modal = _.cloneDeep(this.prov_list);
    } else {
      this.data_prov_modal = [];
    }
  }
  handleValueProvModal(value) {
    if (value) {
      this.prov_modal = value;
      this.getDistrict(this.prov_modal.province_id);
    } else {
      this.prov_modal = null;
    }
  }

  handleFilterLicProvModal(value) {
    if (value.length > 0) {
      this.data_lic_prov_modal = this.prov_list.filter((s) => s.province_name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_lic_prov_modal = _.cloneDeep(this.prov_list);
    } else {
      this.data_lic_prov_modal = [];
    }
  }
  handleValueLicProvModal(value) {
    if (value) {
      this.lic_prov_modal = value;
    } else {
      this.lic_prov_modal = null;
    }
  }

  handleFilterDistModal(value) {
    if (value.length > 0) {
      this.data_dist_modal = this.dist_list.filter((s) => s.dist_name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_dist_modal = _.cloneDeep(this.dist_list);
    } else {
      this.data_dist_modal = [];
    }
  }
  handleValueDistModal(value) {
    if (value) {
      this.dist_modal = value;
    } else {
      this.dist_modal = null;
    }
  }

  handleFilterOwnerModal(value) {
    if (value.length > 0) {
      this.data_owner_modal = this.owner_list.filter((s) => s.company_name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_owner_modal = _.cloneDeep(this.owner_list);
    } else {
      this.data_owner_modal = [];
    }
  }
  handleValueOwnerModal(value) {
    if (value) {
      this.owner_modal = value;
    } else {
      this.owner_modal = null;
    }
  }

  addStation() {
    this.is_loading = true;
    let req = new ReqAddStation();
    req.name = this.selectItem.name;
    req.status = this.status_modal.id;
    req.station_type = parseInt(this.station_type_modal.id);
    if (this.owner_modal)
      req.owner_id = this.owner_modal.id;
    if (this.station_type_modal.id == StationTypeEnum.Static) {
      if (this.route_modal)
        req.route = this.route_modal;
      if (this.prov_modal)
        req.prov_id = this.prov_modal.province_id;
      if (this.dist_modal)
        req.dist_id = this.dist_modal.dist_id;
      if (this.dir_modal)
        req.dir = this.dir_modal.id;
      req.km = parseInt(this.selectItem.km);
      req.m = parseInt(this.selectItem.m);
      req.lat = parseFloat(this.selectItem.lat);
      req.lng = parseFloat(this.selectItem.lng);
    } else {
      if (this.lic_prov_modal)
        req.licenes_plate_province = this.lic_prov_modal.province_id;
      req.license_plate_number = this.selectItem.license_plate_number;
      req.mdvr_no = this.selectItem.mdvr_no;
      req.chassis_no = this.selectItem.chassis_no;

    }
    this.res_message = "";
    this.stationService.addStation(req).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            this.res_message = `เพิ่ม Station สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getStation();
            }, 100);
            this.addModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }
  editStation() {
    this.is_loading = true;
    let req = new ReqAddStation();
    req.name = this.selectItem.name;
    req.status = this.status_modal.id;
    req.station_type = parseInt(this.station_type_modal.id);
    if (this.owner_modal)
      req.owner_id = this.owner_modal.id;
    if (this.station_type_modal.id == StationTypeEnum.Static) {
      if (this.route_modal)
        req.route = this.route_modal;
      if (this.prov_modal)
        req.prov_id = this.prov_modal.province_id;
      if (this.dist_modal)
        req.dist_id = this.dist_modal.dist_id;
      if (this.dir_modal)
        req.dir = this.dir_modal.id;
      req.km = parseInt(this.selectItem.km);
      req.m = parseInt(this.selectItem.m);
      req.lat = parseFloat(this.selectItem.lat);
      req.lng = parseFloat(this.selectItem.lng);
    } else {
      if (this.lic_prov_modal)
        req.licenes_plate_province = this.lic_prov_modal.province_id;
      req.license_plate_number = this.selectItem.license_plate_number;
      req.mdvr_no = this.selectItem.mdvr_no;
      req.chassis_no = this.selectItem.chassis_no;

    }
    this.res_message = "";
    this.stationService.editStation(req, this.selectItem.id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            this.res_message = `แก้ไข Station สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getStation();
            }, 100);
            this.addModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }

  deleteStation() {
    this.is_loading = true;
    let req = new ReqAddStation();
    req.status = parseInt(this.status_modal.id);
    
    this.res_message = "";
    this.stationService.deleteStationEditStatus(req, this.selectItem.id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            this.res_message = `แก้ไข Station สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getStation();
            }, 100);
            this.deleteModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }

  clearMap() {
    this.map_lat = null;
    this.map_lng = null;
    if (this.marker) {
      this.marker.remove();
    }
  }

  openMapModal() {
    this.mapModal.show();
    if (this.selectItem.lat && this.selectItem.lng) {
      this.map_lat = this.selectItem.lat;
      this.map_lng = this.selectItem.lng;
      let l = [this.selectItem.lng, this.selectItem.lat];
      this.marker = new mapboxgl.Marker()
        .setLngLat(l)
        .addTo(this.map);
      setTimeout(() => { this.map.flyTo({ center: l, zoom: 12 }); }, 800);

    }

    setTimeout(() => { this.map.resize() }, 500);


  }
  //---------------------------------------------------------------------------------------------------------------------//
  initMapModal() {
    this.map = new mapboxgl.Map({
      container: 'map-modal',
      style: environment.mapbox.style,
      zoom: 12,
      center: [100.523186, 13.736717],
    });
    this.map.dragRotate.disable();
    this.map.on('load', (event: mapboxgl.MapEvent) => {
      this.map.setPaintProperty('water', 'fill-color', '#aad3df');

      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false, anchor: 'top', offset: [0, -52]
      });

      this.map.on('click', (e) => {
        if (this.marker) {
          this.marker.remove();
        }
        this.map.flyTo({ center: e.lngLat, zoom: 15 });
        this.map_lat = e.lngLat.lat;
        this.map_lng = e.lngLat.lng;
        this.marker = new mapboxgl.Marker()
          .setLngLat(e.lngLat)
          .addTo(this.map);

      });
    });

  }
  setLatLng() {
    this.selectItem.lat = this.map_lat;
    this.selectItem.lng = this.map_lng;
    this.mapModal.hide();
  }

  station_layer: any = 'WeightStationLayer';
  station_src: any = 'WeightStationSrc';
  station_src_url: any = '/assets/data/station.json';
  stationListFeatures: any[] = [];
  stationList: any = [];
  getStationMap() {
    this.is_loading = true;
    this.stationList = [];
    this.stationListFeatures = [];
    this.data_list = [];
    this.totalItems = 0;
    let status = null;
    let route = null;
    let station = null;
    let owner = null;
    let vendor = null;
    let device_type = null;
    let device_group = null;
    if (this.search_status)
      status = this.search_status.id;
    if (this.search_route)
      route = this.search_route;
    if (this.search_station)
      station = this.search_station.id;
    if (this.search_owner)
      owner = this.search_owner.id;
    if (this.search_vendor)
      vendor = this.search_vendor.vendor_id;
    if (this.search_device_group)
      device_group = this.search_device_group.id;
    if (this.search_device_type)
      device_type = this.search_device_type.id;
    this.stationService.getStation(null, null, null, route, this.search_km_st, this.search_km_en, this.search_m_st, this.search_m_en, status, station, owner, vendor, device_group, device_type).subscribe(
      res => {
        if(res){
          res.data.data.forEach(item => {
            // item.icon = item.current[0].weather[0].icon;
            // item.desc = item.current[0].weather[0].description;
            if (item.lng && item.lat) {
              const coordinates = [item.lng, item.lat];
  
              const geometry = { type: 'Point', coordinates: coordinates };
              const feature = { type: 'Feature', geometry: geometry, properties: item };
              this.stationListFeatures.push(feature);
            }
          });
          this.stationList = res.data.data;
        }
      },
      err => {
        console.log(JSON.stringify(err.statusText));
      });
  }
  initStationMap() {
    this.map_station = new mapboxgl.Map({
      container: 'map-station',
      style: environment.mapbox.style,
      zoom: 7,
      center: [100.523186, 13.736717],
    });
    this.map_station.dragRotate.disable();
    this.map_station.on('load', (event: mapboxgl.MapEvent) => {
      this.map_station.setPaintProperty('water', 'fill-color', '#aad3df');

      this.map_station.loadImage('assets/img/marker/pin-red.png', (error, image) => {
        if (error) { throw error };
        this.map_station.addImage(`pin-${StatusEnum.Inactive}`, image);
      });
      this.map_station.loadImage('assets/img/marker/pin-green.png', (error, image) => {
        if (error) { throw error };
        this.map_station.addImage(`pin-${StatusEnum.Active}`, image);
      });
      this.map_station.loadImage('assets/img/marker/pin-gray.png', (error, image) => {
        if (error) { throw error };
        this.map_station.addImage('pin-null', image);
      });
      this.map_station.loadImage('assets/img/marker/pin-gray.png', (error, image) => {
        if (error) { throw error };
        this.map_station.addImage('pin-', image);
      });
      this.map_station.loadImage('assets/img/marker/pin-gray.png', (error, image) => {
        if (error) { throw error };
        this.map_station.addImage(`pin-${StatusEnum.Delete}`, image);
      });
      //------------------------------------------------------------------------------------------//

      this.map_station.addSource(this.station_src, {
        "type": 'geojson', "data": {
          "type": "FeatureCollection",
          "features": this.stationListFeatures
        }
      });

      //icon w-40 h-40
      this.map_station.addLayer({
        id: this.station_layer,
        source: this.station_src,
        type: 'symbol',
        layout: {
          'icon-allow-overlap': true,
          'icon-image': 'pin-{status}',
          "icon-size": 0.8,
          "icon-offset": [0, 0], //[x,y]
          'text-offset': [0, 0],
          'text-field': '{route}',
          'text-size': 12,
          'text-allow-overlap': true,
          'text-transform': 'uppercase',
        },
        paint: {
          "text-color": "#ffffff"
        },
        "filter": ["==", "$type", "Point"],
      });

      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false, anchor: 'top', offset: [0, -52]
      });

      // this.map.on('click', this.station_layer, (event: MapEvent) => {
      //   this.map.flyTo({ center: event.features[0].geometry.coordinates, zoom: 15 });
      //   const p = event.features[0].properties;
      //   this.clickMapStation(p);
      // });
      this.map_station.on('mouseenter', this.station_layer, (e: mapboxgl.MapEvent) => {
        this.map_station.getCanvas().style.cursor = 'pointer';
        const p = e.features[0].properties;
        const htmltext = `<div class="font-3" style="padding-top: 10px;padding-left: 10px;padding-right: 10px;"><h6>${p.name}</h6></div>`;
        popup.setLngLat(e.features[0].geometry.coordinates)
          .setHTML(htmltext)
          .addTo(this.map_station);
      });
      this.map_station.on('mouseleave', this.station_layer, (e: mapboxgl.MapEvent) => {
        this.map_station.getCanvas().style.cursor = '';
        popup.remove();
      });
      this.updateMap();
    });

  }
  updateMap() {
    if (this.map_station.getSource(this.station_src) != null) {
      this.map_station.getSource(this.station_src).setData({
        'type': 'FeatureCollection',
        'features': this.stationListFeatures
      });
    }
  }
}