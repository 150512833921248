import { Component, OnInit, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash'
import * as moment from 'moment'
import { IncidentService } from 'src/app/services/user/incident.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import * as mapboxgl from 'mapbox-gl';
import { environment } from 'src/environments/environment';
import { DeviceService } from 'src/app/services/user/device.service';
import { PreventableEvent } from '@progress/kendo-angular-dateinputs';
import { CommonService } from 'src/app/services/common.service';
import { permission } from 'src/app/views/incident/menu';
@Component({
  selector: 'app-incident-modal',
  templateUrl: './incident-modal.component.html',
  styleUrls: ['./incident-modal.component.scss']
})
export class IncidentModalComponent implements OnInit {
  @ViewChild('mapIncident') mapIncident: ElementRef;
  allowCustom: boolean = false;
  public event: EventEmitter<any> = new EventEmitter();
  values: any;
  list: any;
  inc_type: any;
  incident_type: any;
  changed: boolean = false
  type: any = null
  name: any
  desc: any
  chkMenu: number = 1
  src_type: any
  src_type_etc: any
  occur_type: any
  asp_type: any
  dir: any
  dirBT: any = 1
  veh_dmg_list: any = []
  st_date_in: any
  en_date_in: any
  noti_date_in: any
  noti_message_in: any
  st_date_out: any
  en_date_out: any
  noti_date_out: any
  noti_message_out: any
  route: any
  data_route: any
  route_list: any
  search_route: any
  search_route2: any
  close_lane_in: any = []
  close_lane_out: any = []
  map: mapboxgl.Map;
  lat = 13.739587;
  lng = 100.526757;
  km1: number;
  m1: number;
  km2: number;
  m2: number;
  dir1: number;
  dir2: number;
  dmg_human_dead: number;
  dmg_human_inj: number;
  dmg_vehicle_dmg: number;
  data_list: string;
  veh_dmg_etc: string;
  contact_sta: number = 0
  actlog_status: any = null;
  incall_type: any
  act_list: any = []
  images: any = []
  msg: any;
  images_incdetail: any = []
  lat2: any;
  lng2: any;
  mk_BT : number = 1
  disabled : boolean = false
  vv={
    edit_detail_id: 104,
    edit_detail: {
        add_data_bank_id: 105,
        add_news_id: 106,
        manage_inc_id: 107
    },
    activity_log_id: 108,
    activity_log: {
        manage_inc_id: 109
    },
    news_id: 111,
    news: {
        add_id: 112,
        info_id: 113,
        edit_id: 114,
        manage_id: 115,
    },
    sign_control_id: 116,
    sign_control: {
        calendar_id: 117,
        change_media_id: 118
    }
}
isEditDetail: boolean = false;
isAddDataBank: boolean = false;
isAddNews: boolean = false;
isManageInc: boolean = false;
isActivityLog: boolean = false;
isActivityLogManageInc: boolean = false;
isNews: boolean = false;
isSignControl: boolean = false;
  constructor(
    private inc_S: IncidentService,
    private bsModalRef: BsModalRef,
    private router: Router,
    private devS: DeviceService,
    private commonService: CommonService
  ) {
    mapboxgl.accessToken = environment.mapbox.accessToken;
  }

  async ngOnInit(){
    this.isEditDetail = await this.commonService.getPermission(permission.incident.detail.edit_detail_id).toPromise();
    this.isAddDataBank = await this.commonService.getPermission(permission.incident.detail.edit_detail.add_data_bank_id).toPromise();
    this.isAddNews = await this.commonService.getPermission(permission.incident.detail.edit_detail.add_news_id).toPromise();
    this.isManageInc = await this.commonService.getPermission(permission.incident.detail.edit_detail.manage_inc_id).toPromise();
    
    this.isActivityLog = await this.commonService.getPermission(permission.incident.detail.activity_log_id).toPromise();
    this.isActivityLogManageInc = await this.commonService.getPermission(permission.incident.detail.activity_log.manage_inc_id).toPromise();
    
    this.isNews = await this.commonService.getPermission(permission.incident.detail.news_id).toPromise();
    this.isSignControl = await this.commonService.getPermission(permission.incident.detail.sign_control_id).toPromise();

    this.incall_type = this.inc_type
    if (this.values) {
      this.disabled = true
      if (this.values.actions.length) {
        this.act_list = this.values.actions
      }
      if (!_.isEmpty(this.values.close_lane.dir_in)) {
        let dir = this.values.close_lane.dir_in
        this.close_lane_in = dir.lane_list
        this.st_date_in = (dir.st_date) ? new Date(dir.st_date) : ''
        this.en_date_in = (dir.en_date) ? new Date(dir.en_date) : ''
        this.noti_date_in = (dir.noti_date) ? new Date(dir.noti_date) : ''
        this.noti_message_in = dir.noti_message
      }
      if (!_.isEmpty(this.values.close_lane.dir_out)) {
        let dir = this.values.close_lane.dir_out
        this.close_lane_out = dir.lane_list
        this.st_date_out = (dir.st_date) ? new Date(dir.st_date) : ''
        this.en_date_out = (dir.en_date) ? new Date(dir.en_date) : ''
        this.noti_date_out = (dir.noti_date) ? new Date(dir.noti_date) : ''
        this.noti_message_out = dir.noti_message
      }
      if (this.values.img_urls.length) {
        this.images_incdetail = this.values.img_urls
      }
      this.name = this.values.name || null
      this.desc = this.values.desc || null
      // this.incident_type = this.values.occur_type || null
      this.incident_type = this.values.occur_type_obj || null
      this.src_type = this.values.src_type || null
      this.src_type_etc = this.values.src_type_etc || null
      this.asp_type = this.values.asp_type || null

      this.search_route = (this.values.location.point1) ? this.values.location.point1.route : null
      this.dir1 = (this.values.location.point1) ? this.values.location.point1.dir : null
      this.km1 = (this.values.location.point1) ? this.values.location.point1.km : null
      this.m1 = (this.values.location.point1) ? this.values.location.point1.m : null
      this.lat = (this.values.location.point1) ? this.values.location.point1.coordinate.lat : null
      this.lng = (this.values.location.point1) ? this.values.location.point1.coordinate.lng : null

      this.search_route2 = (this.values.location.point2) ? this.values.location.point2.route : null
      this.dir2 = (this.values.location.point2) ? this.values.location.point2.dir : null
      this.km2 = (this.values.location.point2) ? this.values.location.point2.km : null
      this.m2 = (this.values.location.point2) ? this.values.location.point2.m : null
      this.lat2 = (this.values.location.point2) ? this.values.location.point2.coordinate.lat : null
      this.lng2 = (this.values.location.point2) ? this.values.location.point2.coordinate.lng : null

      this.dmg_human_dead = this.values.damage.human_dead || null
      this.dmg_human_inj = this.values.damage.human_inj || null
      this.dmg_vehicle_dmg = this.values.damage.vehicle_dmg || null
      if (this.values.veh_dmg_list) {
        let dmg_list = this.values.veh_dmg_list.split(',')
        for (const i of dmg_list) {
          let dmg = Number(i)
          this.veh_dmg_list[dmg] = true
        }
      } else {
        this.veh_dmg_list = []
      }
      this.veh_dmg_etc = this.values.veh_dmg_etc || null
    } else {
      this.Closelane()
    }
    this.getData()
    // setTimeout(() => {
    //   this.buildMap();

    // }, 2000);
  }
  closelane_: any = []
  async SetCloselane() {
    this.close_lane_in = await this.Closelane()
    this.close_lane_out = await this.inc_S.getIncidentName()
  }
  async Closelane() {
    if (!this.closelane_.length) this.closelane_ = await this.inc_S.getIncidentName()
    this.close_lane_in = _.cloneDeep(this.closelane_);
    this.close_lane_out = _.cloneDeep(this.closelane_);
    return this.closelane_
  }
  ngAfterViewInit() {
    this.buildMap();

  }
  async getData() {
    this.route_list = await this.devS.getRoute()
    this.route = this.route_list
  }
  async save() {
    let ur = JSON.parse(localStorage.currentUserWebAppShw)
    let all: any = {
      name: this.name || null,
      desc: this.desc || null,
      occur_type: (this.incident_type) ? this.incident_type.id : null,
      src_type: this.src_type || null,
      src_type_etc: this.src_type_etc || null,
      asp_type: (this.asp_type || this.asp_type == 0) ? Number(this.asp_type) : null,
      km1: (this.km1 || this.km1 == 0) ? this.km1 : null,
      route1: this.search_route || null,
      m1: (this.m1 || this.m1 == 0) ? this.m1 : null,
      dir1: (this.dir1 || this.dir1 == 0) ? this.dir1 : null,
      lat1: this.lat,
      lng1: this.lng,
      route2: this.search_route2 || null,
      km2: (this.km2 || this.km2 == 0) ? this.km2 : null,
      m2: (this.m2 || this.m2 == 0) ? this.m2 : null,
      dir2: (this.dir2 || this.dir2 == 0) ? this.dir2 : null,
      lat2: (this.asp_type==2)? this.lat2:null,
      lng2: (this.asp_type==2)? this.lng2:null,
      dmg_human_dead: Number(this.dmg_human_dead),
      dmg_human_inj: Number(this.dmg_human_inj),
      dmg_vehicle_dmg: Number(this.dmg_vehicle_dmg),
      veh_dmg_list: [],
      veh_dmg_etc: this.veh_dmg_etc,
      img_urls: this.images_incdetail,
      close_lane: {
        dir_in: {
          lane_list: [],
          st_date: this.st_date_in || null,
          en_date: this.en_date_in || null,
          noti_date: this.noti_date_in || null,
          noti_message: this.noti_message_in || null
        },
        dir_out: {
          lane_list: [],
          st_date: this.st_date_out || null,
          en_date: this.en_date_out || null,
          noti_date: this.noti_date_out || null,
          noti_message: this.noti_message_out || null
        },
      },
      creator: `${ur.firstname} ${ur.lastname}`,
    }
    let chk = _.indexOf(this.veh_dmg_list, true)
    if (this.veh_dmg_list.length && (chk>0)) {
      let dl = []
      for (const i in this.veh_dmg_list) {
        dl.push(i)
      }
      all.veh_dmg_list = dl.toString()
    }else{
      all.veh_dmg_list = null
    }
    if (this.search_route2 && this.asp_type == 2) all.router2 = this.search_route2 || null
    if (this.values && this.values.id) all.id = this.values.id
    if (this.close_lane_in.length) {
      for (const i of this.close_lane_in) {
        all.close_lane.dir_in.lane_list.push({
          lane_id: i.id || null,
          checked: i.checked || false,
          direction: 1,
          km1: (i.km1 || i.km1 == 0) ? i.km1 : null,
          m1: (i.m1 || i.m1 == 0) ? i.m1 : null,
          km2: (i.km2 || i.km2 == 0) ? i.km2 : null,
          m2: (i.m2 || i.m2 == 0) ? i.m2 : null
        })
      }
    }
    if (this.close_lane_out.length) {
      for (const i of this.close_lane_out) {
        all.close_lane.dir_out.lane_list.push({
          lane_id: i.id || null,
          checked: i.checked || false,
          direction: 2,
          km1: (i.km1 || i.km1 == 0) ? i.km1 : null,
          m1: (i.m1 || i.m1 == 0) ? i.m1 : null,
          km2: (i.km2 || i.km2 == 0) ? i.km2 : null,
          m2: (i.m2 || i.m2 == 0) ? i.m2 : null
        })
      }
    }
    let res: any = await this.inc_S.addIncident(all)
    if (res) this.confirm()
  }
  close(chk?: any) {
    if (chk && !_.isEmpty(this.values)) {
      this.confirm()
    } else {
      this.bsModalRef.hide();
    }
  }

  confirm() {
    this.event.emit(true);
    this.bsModalRef.hide();
  }
  cancel() {
    if (this.changed) this.confirm
    this.event.emit(false);
    this.bsModalRef.hide();
  }
  gotoSign() {
    let data: any = { fromIncident: true }
    if (this.values.location.point1) data.route = this.values.location.point1.route
    if (this.values.location.point1) data.st_km = this.values.location.point1.km
    if (this.values.location.point1) data.st_m = this.values.location.point1.m
    if (this.values.location.point2) data.en_km = this.values.location.point2.km
    if (this.values.location.point2) data.en_m = this.values.location.point2.m
    this.router.navigate([`sign-control/sign`, data]);
    this.bsModalRef.hide();
  }
  FilterType(evt: any) {
    if (evt.length > 0) {
      this.inc_type = this.inc_type.filter(res => res.includes(evt))
    } else if (evt.length == 0) {
      this.inc_type = this.incall_type
    } else {

    }
  }
  FilterRoute(evt: any) {
    if (evt.length > 0) {
      this.route_list = this.route_list.filter(res => res.includes(evt))
    } else if (evt.length == 0) {
      this.route_list = this.route
    } else {

    }
  }
  async ChageRoute(evt) {
    // console.log(evt)
  }
  changeIncType(evt) {
    // console.log(evt)
    this.incident_type = evt
  }
  selectedFile: ImageSnippet;
  async uploadFile(imageInput: any, type?: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();

    // const test_data1 = this.data
    reader.addEventListener('load', async (event: any) => {
      this.selectedFile = new ImageSnippet(event.target.result, file);
      let res: any = (type == 1) ? await this.inc_S.uploadImages(this.selectedFile.file, "incident", "incident_media") : await this.inc_S.uploadImages(this.selectedFile.file, "incident", "incident_log_media")
      if (res && res.img_urls.length) {
        for (const i of res.img_urls) {
          if (type == 1) {
            this.images_incdetail.push(i)
          } else {
            this.images.push(i)
          }
        }
        // this.data.product_img_url_1 = res.path

        // this.data.ShopOwner.shop_owner_img = res.path;
        // console.log(this.data.ShopOwner.shop_owner_img)
      }

    });

    reader.readAsDataURL(file);
  }
  async sendMsg() {
    let ur = JSON.parse(localStorage.currentUserWebAppShw)
    let all: any = {
      incident_id: this.values.id,
      desc: this.msg,
      status: this.actlog_status,
      image: this.images,
      create_by: `${ur.firstname} ${ur.lastname}`,
    }
    let res: any = await this.inc_S.addIncidentAct(all)
    if (res) {
      this.values = await this.inc_S.getIncidentById(this.values.id)
      if (this.values.actions.length) {
        this.act_list = this.values.actions
      }
      this.images = []
      // this.actlog_status = null
      this.msg = ""
    }
  }
  async rmImage(path: string) {
    let res: any = await this.inc_S.rmImages(path)
    if (res) {
      this.images = _.pull(this.images, path);
    }
  }
  // Map=============================
  coordinates: any

  buildMap() {
    // var canvas = this.map.getCanvasContainer();
    this.coordinates = document.getElementById('coordinates');
    try {
      this.map = new mapboxgl.Map({
        // container: 'map-incident',
        container: this.mapIncident.nativeElement,
        style: environment.mapbox.style,
        zoom: 13,
        center: [this.lng, this.lat]
      });

      this.map.on('load', (event) => {
        this.map.resize()
        this.addSourceMap();
        this.addMapLayer();
        this.addMapLayer2()
        this.addSourceMap2()
      })
      this.map.on('click', (event) => {
        if(!this.disabled){
          this.lng = event.lngLat.lng;
          this.lat = event.lngLat.lat;
          let data :any = {
            'type': 'FeatureCollection',
            'features': [
              {
                'type': 'Feature',
                'geometry': {
                  'type': 'Point',
                  'coordinates': [event.lngLat.lng,event.lngLat.lat]
                }
              }
            ]
          }
          this.updateSourceMap(data)
          this.flyTo(data.features[0])
        }
      })
      // this.map.on('mousedown', 'mk', function (event) {

      // }.bind(this));
    } catch (err) {
      console.log("error from mapbox" + err);
    }
  }

  flyTo(data: any) {
    this.map.flyTo({
      center: data.geometry.coordinates
    })
  }
  updateSourceMap(data) {
    if(this.mk_BT==1) this.map.getSource('marker').setData(data);
    if(this.mk_BT==2) this.map.getSource('marker2').setData(data);
  }
  aspChange(){
    if(this.asp_type==1){ 
      this.mk_BT=1
      this.map.setLayoutProperty('mk2', 'visibility', 'none');
    }
    if(this.asp_type==2){ 
      this.map.setLayoutProperty('mk2', 'visibility', 'visible');
    }
  }
  addMapLayer() {
    // this.map.loadImage('https://tmc.taxiok.dlt.go.th/manage/assets/images/taxi-icon.png', function (error, image) {
    this.map.loadImage('assets/img/marker/Marker.png', function (error, image) {
      if (error) console.error(error);
      this.map.addImage('marker', image);
      this.map.addLayer({
        id: "mk",
        type: "symbol",
        source: "marker",
        draggable: true,
        filter: ["!has", "point_count"],
        layout: {
          "icon-image": "marker",
          "icon-size": 0.10,
          "icon-allow-overlap": true,
          //"icon-rotate": 70,
          "icon-rotate": {
            type: "identity",
            property: "heading",
            default: 0
          }
        }
      });
    }.bind(this));
  }
  addSourceMap() {
    this.map.addSource('marker', {
      type: 'geojson',
      data: {
        'type': 'FeatureCollection',
        'features': [
          {
            'type': 'Feature',
            'geometry': {
              'type': 'Point',
              // 'coordinates': [lnglat.lng,lnglat.lat]
              'coordinates': [this.lng, this.lat]
            }
          }
        ]
      },
      // data: [this.lng, this.lat],
      cluster: true,
      clusterMaxZoom: 11, // Max zoom to cluster points on
      clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
    });
  }
  addMapLayer2() {
    // this.map.loadImage('https://tmc.taxiok.dlt.go.th/manage/assets/images/taxi-icon.png', function (error, image) {
    this.map.loadImage('assets/img/marker/Marker2.png', function (error, image) {
      if (error) console.error(error);
      this.map.addImage('marker2', image);
      this.map.addLayer({
        id: "mk2",
        type: "symbol",
        source: "marker2",
        draggable: true,
        filter: ["!has", "point_count"],
        layout: {
          "icon-image": "marker2",
          "icon-size": 0.10,
          "icon-allow-overlap": true,
          //"icon-rotate": 70,
          "icon-rotate": {
            type: "identity",
            property: "heading",
            default: 0
          }
        }
      });
    }.bind(this));
  }
  addSourceMap2() {
    this.map.addSource('marker2', {
      type: 'geojson',
      data: {
        'type': 'FeatureCollection',
        'features': [
          {
            'type': 'Feature',
            'geometry': {
              'type': 'Point',
              // 'coordinates': [lnglat.lng,lnglat.lat]
              'coordinates': [this.lng2, this.lat2]
            }
          }
        ]
      },
      // data: [this.lng, this.lat],
      cluster: true,
      clusterMaxZoom: 11, // Max zoom to cluster points on
      clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
    });
  }
  // public handlePreventableEvent(event: PreventableEvent, eventName: string, preventDefault: boolean): void {
  //   if (preventDefault) {
  //       event.preventDefault();
  //   }

  //   console.log(eventName);
  // }
}

class ImageSnippet {
  constructor(public src: string, public file: File) { }
}
